import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useAdminDetailRequest from "./api/useAdminDetailRequest"
import useAdminUpdateRequest from "./api/useAdminUpdateRequest"

import TitleArea from "widgets/titleArea"
import AdminWriteArea from "widgets/adminWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import { valueToKey } from "shared/const/accountRole"

const AdminUpdate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const userIdx = params.idx

    const idRef = useRef()
    const pwRef = useRef()
    const pwConfirmRef = useRef()
    const nameRef = useRef()
    const authRef = useRef()

    const [getAdminDetailState] = useAdminDetailRequest(userIdx)
    const [adminUpdateEvent] = useAdminUpdateRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/admin")
        setPageNameState("계정 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => {
                navigate(-1)
            }
        },
        {
            "label": "저장",
            "clickEvent": () => {
                adminUpdateEvent(
                    userIdx,
                    idRef?.current?.value,
                    pwRef?.current?.value,
                    pwConfirmRef?.current?.value,
                    nameRef?.current?.value,
                    getAdminDetailState?.data.authorityName,
                    valueToKey[authRef?.current?.options[authRef?.current.selectedIndex].value]
                )
            }
        }
    ]

    return (
        <Style.Main>
            <TitleArea label="관리자 계정 수정" buttonList={buttonList} />
            <AdminWriteArea
                idRef={idRef}
                pwRef={pwRef}
                pwConfirmRef={pwConfirmRef}
                nameRef={nameRef}
                authRef={authRef}
                initData={getAdminDetailState?.data}
            />
        </Style.Main>
    )
}

export default AdminUpdate