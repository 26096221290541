import styled from "styled-components"

export default {
    "BgDiv": styled.div`
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        min-height: 100vh;
        background-color: ${({ theme }) => theme.color.opacity};
    `,
    "Spinner": styled.img`
        width: 64px;
    `
}