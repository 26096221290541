import styled from "styled-components";

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
        gap: 24px;
    `,
    "ExplainWrapper": styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    `,
    "ExplainLabel": styled.p`
        ${({ theme }) => theme.font.extra_small};
    `
}