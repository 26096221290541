import React from "react";

import Style from "./style"

const TitleLabel = ({ title, imgPath, label, caution, center }) => {

    return (
        <Style.Wrapper>
            <Style.Title>{title}</Style.Title>
            {imgPath &&
                <Style.ImgWrapper $center={center}>
                    <Style.Img src={imgPath} />
                </Style.ImgWrapper>
            }
            {label && <Style.Label $caution={caution}>{label}</Style.Label>}
        </Style.Wrapper>
    )
}

export default TitleLabel