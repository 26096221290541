import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 514px;
        border-radius: 20px;
        background-color: ${({ theme }) => theme.color.white};
        padding: 72px 48px;
    `,
    "InputDiv": styled.div`
        width: 100%;
        margin-top: 48px;
    `,
    "TitleLabel": styled.h2`
        ${({ theme }) => theme.font.large_bold}
    `,
    "BtnDiv": styled.div`
        width: 100%;
        margin-top: 64px;
    `
}