import React from "react";

import Style from "./style"

const InputBtn = ({ placeholder, inputRef, maxLength, btnLabel, btnEvent }) => {

    return (
        <Style.WrapperDiv>
            <Style.Input type={"text"} placeholder={placeholder} ref={inputRef} maxLength={maxLength} />
            <Style.Btn onClick={btnEvent}>{btnLabel}</Style.Btn>
        </Style.WrapperDiv>
    )
}

export default InputBtn