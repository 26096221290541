import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";
import MultipleTitleLabel from "features/multipleTitleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";
import convertMoney from "shared/lib/convertMoney";

const BrandDetailArea = ({ dataDetail }) => {

    const firstData = [
        {
            "title": "설립년월",
            "label": dataDetail?.buildDate
        },
        {
            "title": "가맹점 수",
            "label": dataDetail?.franchiseeCount
        },
        {
            "title": "전체 월 평균 매출",
            "label": `${convertMoney(dataDetail?.totalMonthlySalesAvg)} 원`
        }
    ]
    const secondData = [
        {
            "title": "가맹비",
            "label": `${convertMoney(dataDetail?.franchiseeFee)} 원`
        },
        {
            "title": "교육비",
            "label": `${convertMoney(dataDetail?.educateFee)} 원`
        },
        {

        }
    ]

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.brandId} />
            <TitleLabel title={"작성자"} label={dataDetail?.author} />
            <TitleLabel title={"브랜드 명"} label={dataDetail?.brandName} />
            <TitleLabel title={"명함"} imgPath={dataDetail?.brandCardImage?.imageUrl} />
            <MultipleTitleLabel dataList={firstData} />
            <MultipleTitleLabel dataList={secondData} />
            <TitleLabel title={"메뉴 및 브랜드 상세 사항"} label={dataDetail?.menuDetail} />
            <TitleLabel title={"연락처"} label={dataDetail?.phone} />
            <TitleLabel title={"생성 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
        </Style.Wrapper>
    )
}

export default BrandDetailArea