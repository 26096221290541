import { useEffect } from "react";

import Style from "./style"
import caution404 from "./asset/caution404.svg"

import usePageAtom from "shared/model/usePageAtom"

const NotFound = () => {
    const [, setPageData] = usePageAtom()

    useEffect(() => setPageData("/404"), [])

    return (
        <Style.WrapperDiv>
            <Style.CautionSvg src={caution404} />
            <Style.Title>죄송합니다.</Style.Title>
            <Style.Content>해당 페이지를 찾을 수 없습니다.</Style.Content>
        </Style.WrapperDiv>
    )
}

export default NotFound