import Style from "./style"
import useMoveDetailEvent from "./model/useMoveDetailEvent"

import convertTimestamp from "shared/lib/convertTimestamp"

const DataTable = ({ titleList, dataList, titleless }) => {

    const [moveDetailEvent] = useMoveDetailEvent()

    return (
        <Style.WrapperDiv>

            {!titleless &&
                <Style.TableRowDiv $header>
                    {titleList?.map(elem =>
                        <Style.TableElem size={elem?.size} $header>{elem?.label}</Style.TableElem>
                    )}
                </Style.TableRowDiv>}

            {dataList?.length > 0
                ? dataList?.map((row, rowIndex) => {
                    const idx = row?.userId || row?.contentId || row?.propertyId || row?.consultId || row?.customerId || row?.brandId
                    return (
                        <Style.TableRowDiv onClick={() => idx && moveDetailEvent(idx)} $highlight={rowIndex % 2 === 1}>
                            {titleList?.map(elem =>
                                <Style.TableElem size={elem?.size}>{elem?.key === "createdAt" || elem?.key === "hopefulDate"
                                    ? convertTimestamp(row[elem?.key], "datetime")
                                    : row[elem?.key]}
                                </Style.TableElem>
                            )}
                        </Style.TableRowDiv>
                    )
                })
                : <Style.TableRowDiv>
                    <Style.TableElem size="expand">출력할 데이터가 없습니다.</Style.TableElem>
                </Style.TableRowDiv>
            }
        </Style.WrapperDiv>
    )
}

export default DataTable