import styled from "styled-components"

export default {
    "Aside": styled.aside`
        display: flex;
        flex-direction: column;
        flex-basis: 200px;
        flex-shrink: 0;
        position: relative;
    `,
    "ListContainer": styled.div`
        padding: 16px 24px;
    `,
    "CategoryDiv": styled.div`
        display: flex;
        flex-direction: column;
        & + & {
            margin-top: 16px;
        }
    `,
    "ItemTitleLabel": styled.h2`
        ${({ theme }) => theme.font.extra_small_bold}
        margin-bottom: 8px;
    `,
    "ItemElemButton": styled.a`
        ${({ theme, nowPage }) => nowPage
            ? `
                color: ${theme.color.major};
                ${theme.font.extra_small_bold};
            `
            : `
                ${theme.font.extra_small};
            `
        }
        & + & {
            margin-top: 4px;
        }
    `,
    "LogoutBtn": styled.button`
        width: 100%;
        height: 48px;
        position: absolute;
        bottom: 0;
        border: none;
        border-top: 1px solid ${({ theme }) => theme.color.grey};
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.placeholder};
        background-color: ${({ theme }) => theme.color.white};
    `,
}