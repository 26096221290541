import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";
import LabelSelect from "features/labelSelect";

const BrandWriteArea = ({ stateRef, nameRef, addressRef, regionRef, sectorRef, depositRef, keyMoneyRef, areaRef, floorRef, rentRef, royaltyRef, ownerRef, initData }) => {

    const stateOptionList = ["대기", "진행중", "계약완료"]
    const sectorOptionList = ["한식", "중식", "일식", "양식", "아시안푸드", "퓨전음식", "커피전문점", "디저트카페", "베이커리카페", "디저트", "판매점", "오락스포츠", "기타"]
    const regionOptionList = ["서울 강북", "서울 강남", "경기 북부", "경기 남부", "강원도", "충청남도", "충청북도", "경상북도", "경상남도", "전라북도", "전라남도", "인천광역시", "대전광역시", "세종특별자치시", "대구광역시", "울산광역시", "부산광역시", "광주광역시", "제주도", "해외"]
    const ownerOptionList = ["개인 명의", "본사 명의", "특약"]

    return (
        <Style.Wrapper>
            <LabelSelect label={"상태"} optionList={stateOptionList} value={initData?.propertyStatus} inputRef={stateRef} />
            <LabelInput label={"매물 명"} value={initData?.propertyName} placeholder={"최대 50글자 제한"} inputRef={nameRef} maxLength={50} />
            <LabelSelect label={"지역"} optionList={regionOptionList} value={initData?.region} inputRef={regionRef} />
            <LabelInput label={"상세 주소"} value={initData?.address} placeholder={"최대 50글자 제한"} inputRef={addressRef} maxLength={50} />
            <LabelSelect label={"업종"} value={initData?.sector} optionList={sectorOptionList} inputRef={sectorRef} />
            <LabelInput label={"보증금"} value={initData?.deposit} placeholder={"숫자 입력 제한"} inputRef={depositRef} maxLength={20} format={"amount"} />
            <LabelInput label={"시설금 & 권리금"} value={initData?.keyMoney} placeholder={"숫자 입력 제한"} inputRef={keyMoneyRef} maxLength={20} format={"amount"} />
            <LabelInput label={"면적"} value={initData?.area} placeholder={"숫자, 소수 입력 제한 ( m^2 단위로 입력 )"} inputRef={areaRef} maxLength={20} format={"double"} />
            <LabelInput label={"층"} value={initData?.floor} placeholder={"숫자 입력 제한 ( 지하는 - 를 붙여 입력 )"} inputRef={floorRef} maxLength={3} format={"number"} />
            <LabelInput label={"임대료"} value={initData?.rent} placeholder={"숫자 입력 제한"} inputRef={rentRef} maxLength={20} format={"amount"} />
            <LabelInput label={"로열티"} value={initData?.royalty} placeholder={"최대 20글자 제한 ( ex. 매월 매출의 12%, 50만원 등 )"} inputRef={royaltyRef} maxLength={20} />
            <LabelSelect label={"임대 명의"} optionList={ownerOptionList} value={initData?.leaseTitle} inputRef={ownerRef} />
        </Style.Wrapper>
    )
}

export default BrandWriteArea