import { useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useMemoPostRequest = (idx) => {
    const [backendState, setBackendState] = useFetch()

    const requestEvent = (content) => {
        setBackendState("POST", `/admin/consults/memo`, {
            "consultId": idx,
            "paragraph": content
        })
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                window.location.reload()
                break
            case 400:
                console.log("Invalid Request")
                break
        }
    }, [backendState])


    return [requestEvent]
}

export default useMemoPostRequest