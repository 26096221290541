import ReactDom from "react-dom/client"
import { RecoilRoot, RecoilEnv } from "recoil"
import { CookiesProvider } from "react-cookie"
import { BrowserRouter } from "react-router-dom"

import App from "app"

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false

const root = document.getElementById("root")
ReactDom.createRoot(root).render(
    <CookiesProvider>
        <RecoilRoot>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>
    </CookiesProvider>
)