import { atom } from "recoil"
import { useRecoilState, useResetRecoilState } from "recoil"

const useConfirmModalAtom = () => {

    const confrimModalAtom = atom({
        "key": "confrimModalAtom",
        "default": null
    })
    const confirmModalEventAtom = atom({
        "key": "confirmModalEventAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(confrimModalAtom)
    const resetState = useResetRecoilState(confrimModalAtom)

    const [eventState, setEventState] = useRecoilState(confirmModalEventAtom)
    const resetEventState = useResetRecoilState(confirmModalEventAtom)

    const setModalEvent = (label, event) => {
        setState(label)
        setEventState(event)
    }

    const confirmEvent = () => {
        eventState()
        resetState()
        resetEventState()
    }

    const closeEvent = () => {
        resetState()
        resetEventState()
    }

    return [state, setModalEvent, confirmEvent, closeEvent]
}

export default useConfirmModalAtom