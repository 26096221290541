import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useAdminCreateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (id, pw, pwConfirm, name) => {
        if (!id.match(regx.max20)) {
            setErrorModal("아이디 값을 확인해주세요.")
            return false
        }
        if (!pw.match(regx.password)) {
            setErrorModal("비밀번호 값을 확인해주세요.")
            return false
        }
        if (pw !== pwConfirm) {
            setErrorModal("비밀번호가 서로 일치하지 않습니다.")
            return false
        }
        if (!name.match(regx.max10)) {
            setErrorModal("이름 값을 확인해주세요.")
            return false
        }

        return true
    }

    const createEvent = (id, pw, pwConfirm, name, auth) => {
        if (exception(id, pw, pwConfirm, name)) {
            setBackendState("POST", `/admin/accounts`, {
                "account": id,
                "password": pw,
                "name": name,
                "authorityName": auth
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("이미 사용중인 아이디 입니다.")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useAdminCreateRequest