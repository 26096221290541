import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small_bold};
        span {
            color: ${({ theme }) => theme.color.major};
        }
    `,
    "BtnDiv": styled.div`
        display: flex;
        align-items: center;
        gap: 8px;
    `,
    "OptionBtnDiv": styled.div`
        display: flex;
        gap: 8px;
    `,
    "Btn": styled.button`
        width: fit-content;
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.major};
        border: 1px solid ${({ theme }) => theme.color.major};
        border-radius: 4px;
        padding: 12px 16px;
        transition: all 0.2s;

        ${({ $okay }) => {
            return $okay && css`
                color: ${({ theme }) => theme.color.pass};
                border: 1px solid ${({ theme }) => theme.color.pass};
            `
        }};
    `,
    "File": styled.p`
        ${({ theme }) => theme.font.extra_small};
    `,
    "TextArea": styled.textarea`
        ${({ theme }) => theme.font.extra_small};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.color.placeholder};
        padding: 12px 16px;
        height: 600px;

        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.color.major};
            border-radius: 10px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
            background-clip: padding-box;
            border: 8px solid transparent;
        }
    `,
    "Input": styled.input`
        ${({ theme }) => theme.font.extra_small};
        flex-grow: 1;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.color.placeholder};
        padding: 12px 16px;
    `
}