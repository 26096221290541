import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        flex-direction: column;
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small_bold};
        span {
            color: ${({ theme }) => theme.color.major};
        }
    `,
    "SelectDiv": styled.div`
        display: flex;
        width: 280px;
        border: 1px solid ${({ theme }) => theme.color.placeholder};
        border-radius: 4px;
        margin-top: 16px;
        background: url(${({ src }) => src});
        background-position: right 16px center;
        background-repeat: no-repeat;
    `,
    "Select": styled.select`
        ${({ theme }) => theme.font.extra_small};
        flex-grow: 1;
        border: none;
        background-color: #00000000;
        padding: 12px 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    `
}