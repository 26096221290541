import { atom } from "recoil"
import { useRecoilState, useResetRecoilState } from "recoil"

const usePageAtom = () => {

    const pageAtom = atom({
        "key": "pageAtom",
        "default": "/"
    })

    const [state, setState] = useRecoilState(pageAtom)
    const resetState = useResetRecoilState(pageAtom)

    return [state, setState, resetState]
}

export default usePageAtom