import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        gap: 16px;
    `,
    "PageDiv": styled.div`
        display: flex;
        gap: 8px;
    `,
    "PageButton": styled.button`
        ${({ theme }) => theme.font.small}
        width: 32px;
        height: 32px;
        line-height: 1;
        border-radius: 4px;
        color: ${({ theme, active }) => active && theme.color.white};
        background-color: ${({ theme, active }) => active && theme.color.major};
    `,
    "Button": styled.button`
        ${({ theme }) => theme.font.small}
        color: ${({ theme, active }) => !active && theme.color.placeholder};
        cursor: ${({ active }) => !active && "auto"};
    `,
}