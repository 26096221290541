import { useState, useEffect, useRef } from "react"

import Style from "./style"

import getFileType from "shared/lib/getFileType"
import useErrorModalAtom from "shared/model/useErrorModalAtom"

// maxLength: integer
// format: enum(amount, number, double, contact, date)
const LabelInput = ({ type = "text", label, value, setFile, placeholder, inputRef, maxLength, format, btnLabel, okay, btnEvent, resetEvent }) => {
    const fileRef = useRef()
    const [fileName, setFileName] = useState("첨부된 파일이 없습니다.")
    const [, setErrorModalState] = useErrorModalAtom()

    useEffect(() => {
        type === "file" && value && setFileName(value)
    }, [value])

    const fileOpenEvent = () => {
        fileRef.current.click()
    }

    const fileChangeEvent = (e) => {
        const file = e.target.files[0]
        if (!file) {
            setErrorModalState("파일이 선택되지 않았습니다.")
        } else if (!["jpg", "png"].includes(getFileType(file.name).toLowerCase())) {
            setErrorModalState("jpg, png 파일만 업로드 가능합니다.")
        } else if (Math.round(file.size) > 10485760) {
            setErrorModalState("10mb 이하 파일만 업로드 가능합니다.")
        } else {
            setFileName(`${file.name}`)
            setFile(file)
        }
    }

    const replaceFormatEvent = (e, type) => {
        const { value } = e.target
        let convert = null
        switch (type) {
            case "amount":
                convert = value
                    .replace(/[^0-9]/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                break
            case "number":
                convert = value
                    .replace(/[^0-9-]/g, "")
                break
            case "double":
                convert = value
                    .replace(/[^0-9.]/g, "")
                break
            case "contact":
                convert = value
                    .replace(/[^0-9]/g, "")
                    .replace(/^(\d{0,2})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
                    .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
                    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                    .replace(/(-{1,2})$/g, "")
                break
            case "date":
                convert = value
                    .replace(/[^0-9]/g, "")
                    .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
                    .replace(/(-{1,2})$/g, "")
                break
            default:
                convert = value
        }
        inputRef.current.value = convert
    }

    return (
        <Style.WrapperDiv>

            <Style.Label>{label}{<span>*</span>}</Style.Label>
            {type === "file"

                // 파일 입력 양식
                ? <Style.BtnDiv onChange={fileChangeEvent}>
                    <Style.Btn onClick={fileOpenEvent}>{btnLabel}</Style.Btn>
                    <input ref={fileRef} type={type} accept="image/*" style={{ display: "none" }} />
                    <Style.File>{fileName}</Style.File>
                </Style.BtnDiv>

                // Textarea 양식
                : type === "textarea"
                    ? <Style.TextArea placeholder={placeholder} defaultValue={value} ref={inputRef}></Style.TextArea>
                    : btnLabel

                        // Input & Button 양식
                        ? <Style.OptionBtnDiv>
                            <Style.Input type={type} placeholder={placeholder} defaultValue={value} ref={inputRef} maxLength={maxLength} onChange={(e) => {
                                resetEvent()
                                replaceFormatEvent(e, format)
                            }} />
                            <Style.Btn $okay={okay} onClick={btnEvent}>{okay ? `사용 가능` : btnLabel}</Style.Btn>
                        </Style.OptionBtnDiv>

                        // Input 양식
                        : <Style.Input type={type} placeholder={placeholder} defaultValue={value} ref={inputRef} maxLength={maxLength} onChange={(e) => replaceFormatEvent(e, format)} />}

        </Style.WrapperDiv>
    )
}

export default LabelInput