import calFontStyle from "./lib/calFontStyle"

const windowSize = {
    "mobile": `screen and (max-width: '360px')`,
    "normal": `screen and (max-width: '1920px')`,
}

const flex = {

}

const font = {
    "extra_small": calFontStyle("normal", "12px", "400", "normal", "-2%"),
    "extra_small_bold": calFontStyle("bold", "12px", "400", "normal", "-2%"),
    "small": calFontStyle("normal", "16px", "400", "normal", "-2%"),
    "small_bold": calFontStyle("bold", "16px", "400", "normal", "-2%"),
    "medium": calFontStyle("normal", "20px", "400", "normal", "-2%"),
    "medium_bold": calFontStyle("bold", "20px", "400", "normal", "-2%"),
    "large": calFontStyle("normal", "24px", "400", "normal", "-2%"),
    "large_bold": calFontStyle("bold", "24px", "400", "normal", "-2%"),
    "extra_large": calFontStyle("normal", "32px", "400", "normal", "-2%"),
    "heading": calFontStyle("bold", "48px", "400", "normal", "-2%"),
}

const color = {
    "white": "#FFFFFF",
    "grey": "#EFEFEF",
    "black": "#363342",
    "major": "#093191",
    "caution": "#E95252",
    "placeholder": "#A5A4A8",
    "opacity": "#36334266",
    "pass": "#88D849"
}

const theme = {
    windowSize,
    flex,
    font,
    color
}

export default theme