import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";
import convertMoney from "shared/lib/convertMoney";

const CustomerDetailArea = ({dataDetail}) => {

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.customerId} />
            <TitleLabel title={"작성자"} label={dataDetail?.author} />
            <TitleLabel title={"고객 명"} label={dataDetail?.customerName} />
            <TitleLabel title={"예산"} label={convertMoney(dataDetail?.budget)} />
            <TitleLabel title={"연락처"} label={dataDetail?.phone} />
            <TitleLabel title={"주소"} label={dataDetail?.address} />
            <TitleLabel title={"생성 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
            <TitleLabel title={"상세설명 (기타사항)"} label={dataDetail?.detail} />
        </Style.Wrapper>
    )
}

export default CustomerDetailArea