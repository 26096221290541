import { atom, useResetRecoilState } from "recoil"
import { useRecoilState } from "recoil"

const useErrorModalAtom = () => {

    const errorModalAtom = atom({
        "key": "errorModalAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(errorModalAtom)
    const resetState = useResetRecoilState(errorModalAtom)

    const setModalEvent = (label) => {
        setState(label)
    }

    return [state, setModalEvent, resetState]
}

export default useErrorModalAtom