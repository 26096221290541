import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";

const BrandWriteArea = ({ nameRef, setImgFile, birthRef, numberRef, salesRef, franchiseeRef, educateRef, contentRef, contactRef, duplicateOkay, duplicateCheckEvent, resetEvent, initData }) => {

    return (
        <Style.Wrapper>
            <LabelInput label={"브랜드 명"} value={initData?.brandName} placeholder={"최대 50글자 제한"} inputRef={nameRef} maxLength={50} />
            <LabelInput type={"file"} label={"명함"} value={initData?.brandCardImage?.imageUrl} btnLabel={"파일 선택"} setFile={setImgFile} />
            <LabelInput label={"설립년월"} value={initData?.buildDate} placeholder={"10글자 제한, YYYY-MM-DD 형태"} inputRef={birthRef} maxLength={10} format={"date"} />
            <LabelInput label={"가맹점 수"} value={initData?.franchiseeCount} placeholder={"숫자 입력 제한"} inputRef={numberRef} maxLength={10} format={"number"} />
            <LabelInput label={"전체 월 평균 매출"} value={initData?.totalMonthlySalesAvg} placeholder={"숫자 입력 제한"} inputRef={salesRef} maxLength={20} format={"amount"} />
            <LabelInput label={"가맹비"} value={initData?.franchiseeFee} placeholder={"숫자 입력 제한"} inputRef={franchiseeRef} maxLength={20} format={"amount"} />
            <LabelInput label={"교육비"} value={initData?.educateFee} placeholder={"숫자 입력 제한"} inputRef={educateRef} maxLength={20} format={"amount"} />
            <LabelInput type={"textarea"} label={"메뉴 및 브랜드 상세 사항"} value={initData?.menuDetail} inputRef={contentRef} />
            {!initData && <LabelInput label={"연락처"} placeholder={"11~13글자 제한, 000-0000-0000 형태"} inputRef={contactRef} maxLength={13} format={"contact"} btnLabel={"중복 확인"} okay={duplicateOkay} btnEvent={duplicateCheckEvent} resetEvent={resetEvent} />}
        </Style.Wrapper>
    )
}

export default BrandWriteArea