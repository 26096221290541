import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";
import LabelSelect from "features/labelSelect";
import { keyToValue } from "shared/const/accountRole";

const AdminWriteArea = ({ idRef, pwRef, pwConfirmRef, nameRef, authRef, initData }) => {

    const optionList = ["기자", "프로젝트 매니저", "관리자"]

    return (
        <Style.Wrapper>
            <LabelInput label={"아이디"} value={initData?.account} placeholder={"최대 20글자 제한"} inputRef={idRef} maxLength={20} />
            <LabelInput type="password" label={"비밀번호"} value={initData?.password} placeholder={"최대 20글자 제한, 영문+숫자+특수문자 조합"} inputRef={pwRef} maxLength={20} />
            <LabelInput type="password" label={"비밀번호 확인"} value={initData?.password} placeholder={"최대 20글자 제한, 영문+숫자+특수문자 조합"} inputRef={pwConfirmRef} maxLength={20} />
            <LabelInput label={"이름"} placeholder={"최대 10글자 제한"} value={initData?.name} inputRef={nameRef} maxLength={10} />
            <LabelSelect label={"권한"} optionList={optionList} value={keyToValue[initData?.authorityName]} inputRef={authRef} />
        </Style.Wrapper>
    )
}

export default AdminWriteArea