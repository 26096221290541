import styled from "styled-components"

export default {
    "AlignCenterDiv": styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    "MarginDiv": styled.div`
        width: 100%;
        margin-top: ${props => props?.top || "0px"};
        margin-bottom: ${props => props?.bottom || "0px"};
    `
}