import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        gap: 16px;
    `,
    "Input": styled.input`
        flex-grow: 1;
        ${({ theme }) => theme.font.extra_small};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.color.placeholder};
        padding: 12px 16px;
    `,
    "Btn": styled.button`
        width: fit-content;
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.major};
        border-radius: 4px;
        padding: 8px 24px;
    `
}