import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useBrandDetailRequest from "./api/useBrandDetailRequest"
import useBrandDeleteRequest from "./api/useBrandDeleteRequest"
import useMemoPostRequest from "./api/useMemoPostRequest"

import TitleArea from "widgets/titleArea"
import BrandDetailArea from "widgets/brandDetailArea"
import MemoArea from "widgets/memoArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const BrandManagementDetail = () => {
    const params = useParams()
    const brandIdx = params.idx
    const navigate = useNavigate()
    const memoRef = useRef()

    const [, setDeleteModalState] = useConfirmModalAtom()
    const [getBrandDetailState] = useBrandDetailRequest(brandIdx)
    const [deleteBrandEvent] = useBrandDeleteRequest(brandIdx)
    const [postMemoEvent] = useMemoPostRequest(brandIdx)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/brand-management")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "수정",
            "clickEvent": () => { navigate(`/brand-management/update/${brandIdx}`) }
        },
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("브랜드 정보를 삭제할까요?", () => deleteBrandEvent)
        },
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="브랜드 상세보기" buttonList={buttonList} />
                <BrandDetailArea dataDetail={getBrandDetailState?.data} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="이 항목에 대한 메모" />
                <MemoArea data={getBrandDetailState?.data?.brandMemoResList} inputRef={memoRef} postEvent={() => postMemoEvent(memoRef?.current?.value)} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default BrandManagementDetail