import { atom } from "recoil"
import { useRecoilState, useResetRecoilState } from "recoil"


// NOTE: 추후 usePageAtom과 병합
const usePageNameAtom = () => {

    const pageNameAtom = atom({
        "key": "pageNameAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(pageNameAtom)
    const resetState = useResetRecoilState(pageNameAtom)

    return [state, setState, resetState]
}

export default usePageNameAtom