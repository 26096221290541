import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        width: 100%;
    `,
    "TableRowDiv": styled.div`
        width: 100%;
        padding: 12px 0;
        display: flex;
        align-items: center;
        background-color: ${({ theme, $header, $highlight }) => $header ? theme.color.major : $highlight ? theme.color.grey : theme.color.white};
        cursor: ${({ $header }) => $header ? null : "pointer"};
    `,
    "TableElem": styled.p`  
        ${({ theme, $header }) => $header ? theme.font.small_bold : theme.font.extra_small}
        color: ${({ theme, $header }) => $header && theme.color.white};
        line-height: 1;
        ${props =>
            props?.size === "expand"
                ? `
                flex-grow: 1;
                margin-left: 40px;
            `
                : props?.size === "date"
                    ? `
                    width: 176px;
                    text-align: center;
                `
                    : `
                    width: 120px;
                    text-align: center;
                `
        }
    `
}