import React from "react";

import Style from "./style"

const MultipleTitleLabel = ({ dataList }) => {

    return (
        <Style.Wrapper>
            {dataList?.map(elem =>
                <Style.ElemWrapper>
                    <Style.Title>{elem?.title}</Style.Title>
                    <Style.Label>{elem?.label}</Style.Label>
                </Style.ElemWrapper>
            )}
        </Style.Wrapper>
    )
}

export default MultipleTitleLabel