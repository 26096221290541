import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";
import MultipleTitleLabel from "features/multipleTitleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";
import convertMoney from "shared/lib/convertMoney";

const ConsultingDetailArea = ({ dataDetail }) => {

    const customer = dataDetail?.consultCustomerRes
    const property = dataDetail?.consultPropertyRes

    const firstData = [
        {
            "title": "매물명",
            "label": dataDetail?.propertyName
        },
        {
            "title": "매물 주소",
            "label": property?.address
        },
        {
            "title": "임대 명의",
            "label": property?.leaseTitle
        }
    ]
    const secondData = [
        {
            "title": "업종",
            "label": property?.sector
        },
        {
            "title": "면적 & 층",
            "label": `${property?.area} m^2 (${Math.round(property?.area / 3.3 * 100) / 100}평) ${property?.floor}층`
        },
        {
            "title": "임대료",
            "label": `${convertMoney(property?.rent)} 원`
        }
    ]
    const thirdData = [
        {
            "title": "보증금",
            "label": `${convertMoney(property?.deposit)} 원`
        },
        {
            "title": "시설금",
            "label": `${convertMoney(property?.keyMoney)} 원`
        },
        {
            "title": "로열티",
            "label": property?.royalty
        }
    ]

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.consultId} />
            <TitleLabel title={"고객 아이디"} label={customer?.account} />
            <TitleLabel title={"고객 이름"} label={customer?.name} />
            <TitleLabel title={"고객 연락처"} label={customer?.phone} />
            <TitleLabel title={"고객 이메일"} label={customer?.email} />
            <TitleLabel title={"희망 상담 일자"} label={convertTimestamp(dataDetail?.hopefulDate)} />
            <TitleLabel title={"생성 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
            <Style.Line />
            <MultipleTitleLabel dataList={firstData} />
            <MultipleTitleLabel dataList={secondData} />
            <MultipleTitleLabel dataList={thirdData} />
        </Style.Wrapper>
    )
}

export default ConsultingDetailArea