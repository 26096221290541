import { useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useAuthCookie from "shared/model/useAuthCookie"
import useRoleCookie from "shared/model/useRoleCookie"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import { keyToIndex } from "shared/const/accountRole"

const useLoginEvent = (idRef, pwRef) => {

    const [backendState, setBackendState] = useFetch()
    const [, setAuthCookie] = useAuthCookie()
    const [, setRoleCookie] = useRoleCookie()
    const [, setExceptionModal] = useErrorModalAtom()
    const [, setErrorModal] = useErrorModalAtom()

    const postRequest = () => {
        console.log("실행됨")
        if (idRef.current.value !== "" && pwRef.current.value !== "") {
            setBackendState("POST", "/account/admin/signin", {
                "account": idRef.current.value,
                "password": pwRef.current.value
            })
        }
        else setExceptionModal("입력 값을 확인해주세요.")
    }

    const clickEvent = () => {
        postRequest()
    }

    const enterEvent = (e) => {
        if (e.key === "Enter") postRequest()
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setAuthCookie(backendState?.data?.accessToken)
                setRoleCookie(keyToIndex[backendState?.data?.authorityName])
                break
            case 400:
                setErrorModal("정지된 계정입니다.")
                break
            case 404:
                setErrorModal("회원 정보가 존재하지 않습니다.")
                break
        }
    }, [backendState])


    return [clickEvent, enterEvent]
}

export default useLoginEvent