import { useRef } from "react"

import Style from "./style"
import useLoginEvent from "./api/useLoginRequest"

import LabelInput from "features/loginInput"
import ActiveBtn from "features/activeBtn"

const LoginArea = () => {
    const idRef = useRef()
    const pwRef = useRef()

    const [postLoginEvent, enterEvent] = useLoginEvent(idRef, pwRef)

    return (
        <Style.WrapperDiv onKeyUp={enterEvent}>
            <Style.TitleLabel>관리자 로그인</Style.TitleLabel>
            <Style.InputDiv>
                <LabelInput label="아이디" placeholder="아이디를 입력해주세요." inputRef={idRef} />
                <LabelInput type="password" label="비밀번호" placeholder="비밀번호를 입력해주세요." inputRef={pwRef} />
            </Style.InputDiv>
            <Style.BtnDiv>
                <ActiveBtn label="로그인" clickEvent={postLoginEvent} />
            </Style.BtnDiv>
        </Style.WrapperDiv>
    )
}

export default LoginArea 