import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";

const ContentDetailArea = ({ dataDetail }) => {

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.contentId} />
            <TitleLabel title={"작성자"} label={dataDetail?.author} />
            <TitleLabel title={"생성 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
            <TitleLabel title={"제목"} label={dataDetail?.title} />
            <TitleLabel title={"내용"} imgPath={dataDetail?.mainImageUrl} label={dataDetail?.paragraph} center />
        </Style.Wrapper>
    )
}

export default ContentDetailArea