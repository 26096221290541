import React from "react"

import Style from "./style"

import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const ConfirmModal = () => {

    const [label, , confirmEvent, cancelEvent] = useConfirmModalAtom()

    return (
        <Style.BgDiv onClick={cancelEvent}>
            <Style.ModalDiv>
                <Style.Label>{label}</Style.Label>
                <Style.ButtonsDiv>
                    <Style.Btn onClick={confirmEvent}>확인</Style.Btn>
                    <Style.Btn onClick={cancelEvent} cancel>취소</Style.Btn>
                </Style.ButtonsDiv>
            </Style.ModalDiv>
        </Style.BgDiv>
    )
}

export default ConfirmModal
