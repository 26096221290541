import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"

const useBannerListRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        setBackendState("GET", `/contents/banners`)
    }, [])

    useEffect(() => {
        switch (backendState?.code) {
            case 404:
                navigate(`/404`)
                break
        }
    }, [backendState])


    return [backendState]
}

export default useBannerListRequest