export default {
    "max10": /^.{1,10}$/,
    "max20": /^.{1,20}$/,
    "max50": /^.{1,50}$/,
    "max100": /^.{1,100}$/,
    "password": /^(?=.*?[0-9])(?=.*?[a-zA-Z])(?=.*?[#?!@$%^&*-]).{1,20}$/,
    "birth": /^\d{4}-\d{2}-\d{2}$/,
    "number": /^[0-9]+$/,
    "integer": /^[0123456789-]+$/,
    "double": /^[0-9.]+$/,
    "contact": /^\d{2,3}-\d{3,4}-\d{4}$/
}