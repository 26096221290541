import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        height: 100%;
        display: flex;
        flex-direction: column;
    `,
    "ExpandDiv": styled.div`
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `
}