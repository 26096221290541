import styled from "styled-components";

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small};
        color: ${({ theme, $caution }) =>
            $caution ? theme.color.caution : theme.color.placeholder
        };
        margin-top: 8px;
        word-break: break-all;
    `,
    "ImgWrapper": styled.div`
        width: 100%;
        display: flex;
        justify-content: ${({ $center }) => $center ? "center" : null};
        padding: 48px 0px;
    `,
    "Img": styled.img`
        width: 100%;
        max-width: 800px;
    `
}