const calRange = (nowPage, maxPage) => {
    let startIndex = nowPage - ((nowPage - 1) % 5)
    const range = []
    for (let index = startIndex; ; index++) {
        if (index > maxPage) break
        if (index > startIndex + 4) break
        range.push(index)
    }

    return [...range]
}

export default calRange