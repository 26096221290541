import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useCustomerDetailRequest from "./api/useCustomerDetailRequest"
import useCustomerDeleteRequest from "./api/useCustomerDeleteRequest"
import useMemoPostRequest from "./api/useMemoPostRequest"

import TitleArea from "widgets/titleArea"
import CustomerDetailArea from "widgets/customerDetailArea"
import MemoArea from "widgets/memoArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const CustomerManagementDetail = () => {
    const params = useParams()
    const customerIdx = params.idx
    const navigate = useNavigate()
    const memoRef = useRef()

    const [, setDeleteModalState] = useConfirmModalAtom()
    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [getCustomerDetailState] = useCustomerDetailRequest(customerIdx)
    const [deleteCustomerEvent] = useCustomerDeleteRequest(customerIdx)
    const [postMemoEvent] = useMemoPostRequest(customerIdx)

    useEffect(() => {
        setPageState("/customer-management")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = [
        {
            "label": "수정",
            "clickEvent": () => { navigate(`/customer-management/update/${customerIdx}`) }
        },
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("개인 고객 정보를 삭제할까요?", () => deleteCustomerEvent)
        },
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="개인 고객 상세보기" buttonList={buttonList} />
                <CustomerDetailArea dataDetail={getCustomerDetailState?.data} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="이 항목에 대한 메모" />
                <MemoArea data={getCustomerDetailState?.data?.customerMemoResList} inputRef={memoRef} postEvent={() => postMemoEvent(memoRef?.current?.value)} />
            </Style.AreaDiv>
        </Style.WrapperDiv>

    )
}

export default CustomerManagementDetail