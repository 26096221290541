import { atom, useResetRecoilState } from "recoil"
import { useRecoilState } from "recoil"

const useSpinnerAtom = () => {

    const spinnerAtom = atom({
        "key": "spinnerAtom",
        "default": false
    })

    const [state, setState] = useRecoilState(spinnerAtom)
    const resetState = useResetRecoilState(spinnerAtom)

    const openSpinnerEvent = () => {
        setState(true)
    }

    const closeSpinnerEvent = () => {
        resetState()
    }

    return [state, openSpinnerEvent, closeSpinnerEvent]
}

export default useSpinnerAtom