import { useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useAccountListRequest = (page, content) => {

    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        if (content) {
            setBackendState("GET", `/admin/accounts/users/search?page=${page - 1}&query=${content}`)
        } else {
            setBackendState("GET", `/admin/accounts/users?page=${page - 1}`)
        }
    }, [page, content])

    useEffect(() => {
        switch (backendState?.code) {
            case 400:
                console.log("Invalid Request")
                break
        }
    }, [backendState])

    return [backendState]
}

export default useAccountListRequest