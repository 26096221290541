import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import regx from "shared/const/regx"

const useBrandCreateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (name, address, deposite, keyMoney, area, floor, rent, royalty) => {
        if (!name?.match(regx.max50)) {
            setErrorModal("매물 명 값을 확인해주세요.")
            return false
        }
        if (!address?.match(regx.max50)) {
            setErrorModal("주소 값을 확인해주세요.")
            return false
        }
        if (!deposite?.match(regx.number)) {
            setErrorModal("보증금 값을 확인해주세요.")
            return false
        }
        if (!keyMoney?.match(regx.number)) {
            setErrorModal("시설금 값을 확인해주세요.")
            return false
        }
        if (!area?.match(regx.double)) {
            setErrorModal("면적 값을 확인해주세요.")
            return false
        }
        if (!floor?.match(regx.integer)) {
            setErrorModal("층 값을 확인해주세요.")
            return false
        }
        if (!rent?.match(regx.number)) {
            setErrorModal("임대료 값을 확인해주세요.")
            return false
        }
        if (!royalty?.match(regx.max20)) {
            setErrorModal("로열티 값을 확인해주세요.")
            return false
        }

        return true
    }

    const createEvent = (state, name, address, region, sector, deposite, keyMoney, area, floor, rent, royalty, owner) => {
        if (exception(name, address, deposite, keyMoney, area, floor, rent, royalty)) {
            setBackendState("POST", `/admin/properties`, {
                "propertyStatus": state,
                "propertyName": name,
                "address": address,
                "region": region,
                "sector": sector,
                "deposit": deposite,
                "keyMoney": keyMoney,
                "area": area,
                "floor": floor,
                "rent": rent,
                "royalty": royalty,
                "leaseTitle": owner,
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("Invalid Argument")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useBrandCreateRequest