import { Link } from "react-router-dom"

import Style from "./style"
import logo from "./asset/logo.png"

import useRoleCookie from "shared/model/useRoleCookie"
import useUserAtom from "shared/model/useUserAtom"
import { indexToValue } from "shared/const/accountRole"
import usePageNameAtom from "shared/model/usePageNameAtom"

const Header = () => {

    const [roleCookie] = useRoleCookie()
    const [userState] = useUserAtom()
    const [pageNameState] = usePageNameAtom()

    return (
        <>
            {roleCookie
                ? <Style.Header after>
                    <Style.HorDiv>
                        <Link to="/">
                            <Style.LogoImg src={logo} />
                        </Link>
                        <Style.TabNameLabel>{pageNameState}</Style.TabNameLabel>
                    </Style.HorDiv>
                    <Style.NicknameLabel>{indexToValue[roleCookie]} <span>{userState}</span> 님</Style.NicknameLabel>
                </Style.Header>
                : <Style.Header before>
                    <Link to="/">
                        <Style.LogoImg src={logo} />
                    </Link>
                </Style.Header>}
        </>
    )
}

export default Header