import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";

const AdminWriteArea = ({ initData, valueRefList }) => {

    return (
        <>
            <Style.ExplainWrapper>
                <Style.ExplainLabel>* 랜딩 Web 메인 페이지에 출력되는 배너를 수정합니다.</Style.ExplainLabel>
                <Style.ExplainLabel>* 해당 콘텐츠의 idx를 입력합니다.</Style.ExplainLabel>
                <Style.ExplainLabel>* 최대 5개까지 입력할 수 있으며, 사용하지 않을 경우 비워두면 됩니다.</Style.ExplainLabel>
            </Style.ExplainWrapper>
            <Style.Wrapper>
                {valueRefList.map((elem, index) =>
                    <LabelInput label={`${index + 1}번째 배너`} value={initData[index]?.contentId || ""} format={`number`} placeholder={"콘텐츠의 idx를 입력"} inputRef={elem} maxLength={5} />
                )}
            </Style.Wrapper>
        </>

    )
}

export default AdminWriteArea