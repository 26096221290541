import { useEffect } from "react";

import Style from "./style"
import caution500 from "./asset/caution500.svg"

import usePageAtom from "shared/model/usePageAtom"

const InternalServerError = () => {
    const [, setPageData] = usePageAtom()

    useEffect(() => setPageData("/500"), [])

    return (
        <Style.WrapperDiv>
            <Style.CautionSvg src={caution500} />
            <Style.Title>죄송합니다.</Style.Title>
            <Style.Content>서버와의 통신 중에 문제가 발생하였습니다.</Style.Content>
        </Style.WrapperDiv>
    )
}

export default InternalServerError