import React from "react"

import Style from "./style"

const TitleArea = ({ label, buttonList }) => {

    return (
        <Style.WrapperDiv>
            <Style.TitleLabel>{label}</Style.TitleLabel>
            <Style.BtnDiv>
                {buttonList?.map(elem =>
                    <Style.Btn onClick={elem?.clickEvent}>{elem?.label}</Style.Btn>
                )}
            </Style.BtnDiv>
        </Style.WrapperDiv>
    )
}

export default TitleArea 