import { useEffect, useRef } from "react"
import { useParams } from "react-router-dom"

import Style from "./style"
import useConsultingDetailRequest from "./api/useConsultingDetailRequest"
import useConsultingDeleteRequest from "./api/useConsultingDeleteRequest"
import useMemoPostRequest from "./api/useMemoPostRequest"

import TitleArea from "widgets/titleArea"
import ConsultingDetailArea from "widgets/consultingDetailArea"
import MemoArea from "widgets/memoArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const ConsultingDetail = () => {
    const params = useParams()
    const contentIdx = params.idx
    const memoRef = useRef()

    const [, setDeleteModalState] = useConfirmModalAtom()
    const [getConsultingDetailState] = useConsultingDetailRequest(contentIdx)
    const [deleteConsultingEvent] = useConsultingDeleteRequest(contentIdx)
    const [postMemoEvent] = useMemoPostRequest(contentIdx)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/consulting")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = [
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("상담 정보를 삭제할까요?", () => deleteConsultingEvent)
        },
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="상담 상세보기" buttonList={buttonList} />
                <ConsultingDetailArea dataDetail={getConsultingDetailState?.data} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="이 항목에 대한 메모" />
                <MemoArea data={getConsultingDetailState?.data?.memoResList} inputRef={memoRef} postEvent={() => postMemoEvent(memoRef?.current?.value)} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default ConsultingDetail