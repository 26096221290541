export const adminMenuList = [
    {
        "title": "계정 관리",
        "items": [
            {
                "name": "고객 계정 관리",
                "path": "/account"
            },
            {
                "name": "관리자 계정 관리",
                "path": "/admin"
            }
        ]
    },
    {
        "title": "데이터 관리",
        "items": [
            {
                "name": "콘텐츠 관리",
                "path": "/content"
            },
            {
                "name": "매물 관리",
                "path": "/property"
            },
            {
                "name": "배너 관리",
                "path": "/banner/update"
            }
        ]
    },
    {
        "title": "고객 관리",
        "items": [
            {
                "name": "개인 고객 관리",
                "path": "/customer-management"
            },
            {
                "name": "브랜드 관리",
                "path": "/brand-management"
            },
            {
                "name": "상담 관리",
                "path": "/consulting"
            },
        ]
    },
]

export const managerMenuList = [
    {
        "title": "데이터 관리",
        "items": [
            {
                "name": "콘텐츠 관리",
                "path": "/content"
            },
            {
                "name": "매물 관리",
                "path": "/property"
            }
        ]
    },
    {
        "title": "고객 관리",
        "items": [
            {
                "name": "개인 고객 관리",
                "path": "/customer-management"
            },
            {
                "name": "브랜드 관리",
                "path": "/brand-management"
            },
            {
                "name": "상담 관리",
                "path": "/consulting"
            },
        ]
    },
]

export const reporterMenuList = [
    {
        "title": "데이터 관리",
        "items": [
            {
                "name": "콘텐츠 관리",
                "path": "/content"
            },
        ]
    },
]