import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useBannerUpdateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (contentIdxList) => {

        if (contentIdxList.length < 1) {
            setErrorModal("1개 이상의 콘텐츠 idx를 입력해주세요.")
            return false
        }

        contentIdxList?.forEach(elem => {
            if (!elem.match(regx.number)) {
                setErrorModal("idx는 정수여야 합니다.")
                return false
            }
        })

        return true
    }

    const updateEvent = (...contentIdxList) => {

        const newList = contentIdxList?.filter(elem => {
            return elem !== ""
        })

        if (exception([...newList])) {
            setBackendState("POST", `/admin/banners`, {
                "contentIdList": newList,
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                window.location.reload()
                break
            case 400:
                setErrorModal("콘텐츠 idx는 5개를 넘을 수 없습니다.")
                break
            case 404:
                setErrorModal("존재하지 않는 콘텐츠 idx 입니다.")
                break
        }
    }, [backendState])


    return [updateEvent]
}

export default useBannerUpdateRequest