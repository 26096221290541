import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Style from "./style"
import useContentDetailRequest from "./api/useContentDetailRequest"
import useContentDeleteRequest from "./api/useContentDeleteRequest"

import TitleArea from "widgets/titleArea"
import ContentDetailArea from "widgets/contentDetailArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const ContentDetail = () => {
    const params = useParams()
    const contentIdx = params.idx
    const navigatge = useNavigate()
    const [, setDeleteModalState] = useConfirmModalAtom()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [getContentDetailState] = useContentDetailRequest(contentIdx)
    const [deleteContentEvent] = useContentDeleteRequest(contentIdx)

    useEffect(() => {
        setPageState("/content")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "수정",
            "clickEvent": () => { navigatge(`/content/update/${contentIdx}`) }
        },
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("콘텐츠를 삭제할까요?", () => deleteContentEvent)
        },
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="콘텐츠 상세보기" buttonList={buttonList} />
                <ContentDetailArea dataDetail={getContentDetailState?.data} />
            </Style.Main>
        </>
    )
}

export default ContentDetail