import styled from "styled-components";

export default {
    "Wrapper": styled.div`
        display: flex;
    `,
    "ElemWrapper": styled.div`
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.placeholder};
        margin-top: 8px;
    `,

}