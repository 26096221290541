import { useNavigate, useLocation } from "react-router-dom"

const usePagenationEvent = (nowPage, maxPage, searchKey) => {
    const navigate = useNavigate()
    const location = useLocation()

    const prePageEvent = () => {
        if (nowPage > 1) navigate(`${location.pathname}?page=${nowPage - 1}&search=${searchKey}`)
    }

    const movePageEvent = (targetPage) => {
        navigate(`${location.pathname}?page=${targetPage}&search=${searchKey}`)
    }

    const nextPageEvent = () => {
        if (nowPage < maxPage) navigate(`${location.pathname}?page=${nowPage + 1}&search=${searchKey}`)
    }

    return [prePageEvent, movePageEvent, nextPageEvent]
}

export default usePagenationEvent