import { useEffect, useRef } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"

import Style from "./style"
import useConsultingListRequest from "./api/useConsultingListRequest"

import TitleArea from "widgets/titleArea"
import DataListArea from "widgets/dataListArea"
import InputBtn from "features/inputBtn"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const ConsultingtList = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const nowPage = parseInt(searchParams.get("page")) || 1
    const searchKeyword = searchParams.get("search") || ""
    const searchRef = useRef()

    const [getConsultingListState] = useConsultingListRequest(nowPage, searchKeyword)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/consulting")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = []

    const titleList = [
        {
            "key": "consultId",
            "label": "IDX",
        },
        {
            "key": "account",
            "label": "고객 아이디"
        },
        {
            "key": "propertyName",
            "label": "매물 명",
            "size": "expand"
        },
        {
            "key": "hopefulDate",
            "label": "희망 상담 일자",
            "size": "date"
        },
        {
            "key": "createdAt",
            "label": "생성 날짜",
            "size": "date"
        }
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="검색" />
                <InputBtn placeholder={"고객 이름 혹은 매물 명 입력"} inputRef={searchRef} maxLength={20} btnLabel={"검색"} btnEvent={() => navigate(`${location.pathname}?page=1&search=${searchRef?.current.value}`)} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="상담 관리" buttonList={buttonList} />
                <DataListArea nowPage={nowPage} searchKey={searchKeyword} titleList={titleList} dataList={getConsultingListState?.data} expandIndex={2} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default ConsultingtList