import React from "react"

import Style from "./style"

import DataTable from "features/dataTable"
import Pagenation from "features/pagenation"

const DataListArea = ({ nowPage, searchKey, titleList, dataList }) => {
    const maxPage = dataList?.length > 0 ? dataList[0]?.maxPage : 1

    return (
        <Style.AlignCenterDiv>
            <Style.MarginDiv bottom="40px">
                <DataTable titleList={titleList} dataList={dataList} />
            </Style.MarginDiv>
            <Pagenation nowPage={nowPage} maxPage={maxPage} searchKey={searchKey} />
        </Style.AlignCenterDiv>
    )
}

export default DataListArea 