import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useContentUpdateRequest = () => {
    const navigate = useNavigate()

    const [, setErrorModal] = useErrorModalAtom()
    const [backendState, setBackendState] = useFetch()

    const exception = (idx, title, content) => {
        if (!idx.match(regx.number)) {
            setErrorModal("수정하려는 계정의 idx값을 확인해주세요.")
            return false
        }
        if (!title.match(regx.max100)) {
            setErrorModal("제목 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("내용 값을 확인해주세요.")
            return false
        }

        return true
    }

    const createEvent = (idx, title, image, content, oldImagePath) => {
        if (exception(idx, title, content)) {
            const formData = new FormData()
            formData.append("contentId", idx)
            formData.append("title", title)
            formData.append("paragraph", content)
            if (image) {
                formData.append("newImageList", image)
                formData.append("deletedImageList", oldImagePath)
            }
            setBackendState("POST", `/admin/contents/edit`, null, formData)
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("입력 값이 잘못되었습니다.")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useContentUpdateRequest