import Style from "./style"

import InputBtn from "features/inputBtn"
import DataTable from "features/dataTable"

const MemoArea = ({ data, inputRef, postEvent }) => {

    const titleList = [
        {
            "key": "createdAt",
            "label": "작성 날짜",
            "size": "date"
        },
        {
            "key": "paragraph",
            "label": "메모 내용",
            "size": "expand"
        },
        {
            "key": "author",
            "label": "작성자",
        }
    ]

    return (
        <>
            <InputBtn placeholder={"최대 100글자 제한"} inputRef={inputRef} maxLength={100} btnLabel={"작성"} btnEvent={postEvent} />
            <Style.MarginTopDiv>
                <DataTable titleList={titleList} dataList={data} titleless />
            </Style.MarginTopDiv>
        </>
    )
}

export default MemoArea