import styled from "styled-components"

export default {
    "Header": styled.header`
        width: 100%;
        height: 96px;
        display: flex;
        align-items: center;
        box-shadow: 0px 2px 5px 2px ${({ theme }) => theme.color.grey};
        ${props => {
            if (props.before) {
                return `
                    justify-content: center;
                `
            }
            else if (props.after) {
                return `
                    justify-content: space-between;
                    padding: 0 65px;
                `
            }
        }}
    `,
    "HorDiv": styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    "LogoImg": styled.img`
        width: 117px;
    `,
    "TabNameLabel": styled.h2`
        ${({ theme }) => theme.font.medium_bold}
        margin-left: 55px;
    `,
    "NicknameLabel": styled.p`
        ${({ theme }) => theme.font.medium_bold}
        white-space: nowrap;
        span {
            ${({ theme }) => theme.font.medium_bold}
            color: ${({ theme }) => theme.color.major}
        }
    `,
}