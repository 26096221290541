import styled from "styled-components"

export default {
    "ParentDiv": styled.div`
        min-width: 1200px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: ${({ theme }) => theme.color.white};
    `,
    "WrapperDiv": styled.div`
        display: flex;
        flex-grow: 1;
    `
}