import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";

const CustomerWriteArea = ({ nameRef, addressRef, contentRef, contactRef, depositRef, duplicateOkay, duplicateCheckEvent, resetEvent, initData }) => {

    return (
        <Style.Wrapper>
            <LabelInput label={"고객 명"} value={initData?.customerName} placeholder={"최대 10글자 제한"} inputRef={nameRef} maxLength={10} />
            <LabelInput label={"주소"} value={initData?.address} placeholder={"최대 50글자 제한"} inputRef={addressRef} maxLength={50} />
            <LabelInput type={"textarea"} value={initData?.detail} label={"상세설명 (기타사항)"} inputRef={contentRef} />
            {!initData && <LabelInput label={"연락처"} placeholder={"11~13글자 제한, 000-0000-0000 형태"} inputRef={contactRef} maxLength={13} format={"contact"} btnLabel={"중복 확인"} okay={duplicateOkay} btnEvent={duplicateCheckEvent} resetEvent={resetEvent} />}
            <LabelInput label={"예산"} value={initData?.budget} placeholder={"숫자 입력 제한"} inputRef={depositRef} maxLength={12} format={"amount"} />
        </Style.Wrapper>
    )
}

export default CustomerWriteArea