import { atom } from "recoil"
import { useRecoilState, useResetRecoilState } from "recoil"

const useUserAtom = () => {

    const userAtom = atom({
        "key": "userAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(userAtom)
    const resetState = useResetRecoilState(userAtom)

    return [state, setState, resetState]
}

export default useUserAtom