import { useEffect } from "react"
import { useParams } from "react-router-dom"

import Style from "./style"
import useAccountDetailRequest from "./api/useAccountDetailRequest"
import useAccountDeactiveRequest from "./api/useAccountDeactiveRequest"
import useAccountActiveRequest from "./api/useAccountActiveRequest"
import useAccountDeleteRequest from "./api/useAccountDeleteRequest"

import TitleArea from "widgets/titleArea"
import AccountDetailArea from "widgets/accountDetailArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const AccountDetail = () => {
    const params = useParams()
    const userIdx = params.idx
    const [, setDeactiveModalState] = useConfirmModalAtom()
    const [, setDeleteModalState] = useConfirmModalAtom()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [getAccountDetailState] = useAccountDetailRequest(userIdx)
    const [deactiveAccountEvent] = useAccountDeactiveRequest(userIdx)
    const [activeAccountEvent] = useAccountActiveRequest(userIdx)
    const [deleteAccountEvent] = useAccountDeleteRequest(userIdx)

    useEffect(() => {
        setPageState("/account")
        setPageNameState("계정 관리")
    }, [])

    const banBtn = {
        "label": "정지",
        "clickEvent": () => setDeactiveModalState("계정을 정지할까요?", () => deactiveAccountEvent)
    }

    const unbanBtn = {
        "label": "정지 해제",
        "clickEvent": () => setDeactiveModalState("정지를 해제할까요?", () => activeAccountEvent)
    }

    const deleteBtn = {
        "label": "삭제",
        "clickEvent": () => setDeleteModalState("계정을 삭제할까요?", () => deleteAccountEvent)
    }

    const btnList = getAccountDetailState?.data?.state === "BANNED"
        ? [unbanBtn, deleteBtn]
        : [banBtn, deleteBtn]

    return (
        <>
            <Style.Main>
                <TitleArea label="고객 계정 상세보기" buttonList={btnList} />
                <AccountDetailArea dataDetail={getAccountDetailState?.data} />
            </Style.Main>
        </>
    )
}

export default AccountDetail