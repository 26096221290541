import styled from "styled-components"

export default {
    "Btn": styled.button`
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.color.major};
        color: ${({ theme }) => theme.color.white};
        ${({ theme }) => theme.font.medium_bold};
        border-radius: 8px;
    `,
}