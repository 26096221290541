import Style from "./style"
import spinnerImg from "./asset/spinner.gif"

import useSpinnerAtom from "shared/model/useSpinnerAtom"

const Spinner = () => {

    const [, , closeSpinnerEvent] = useSpinnerAtom()

    return (
        <Style.BgDiv onClick={closeSpinnerEvent}>
            <Style.Spinner src={spinnerImg} />
        </Style.BgDiv>
    )
}

export default Spinner