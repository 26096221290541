import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import regx from "shared/const/regx"

const useCustomerUpdateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (idx, name, address, content, deposit) => {
        if (!idx.match(regx.number)) {
            setErrorModal("수정하려는 계정의 idx값을 확인해주세요.")
            return false
        }
        if (!name?.match(regx.max10)) {
            setErrorModal("고객 명 값을 확인해주세요.")
            return false
        }
        if (!address?.match(regx.max50)) {
            setErrorModal("주소 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("상세설명 값을 확인해주세요.")
            return false
        }
        if (!deposit?.match(regx.number)) {
            setErrorModal("예산 값을 확인해주세요.")
            return false
        }

        return true
    }

    const updateEvent = (idx, name, address, content, contact, deposit) => {
        if (exception(idx, name, address, content, deposit)) {
            setBackendState("POST", `/admin/customers/edit`, {
                "customerId": idx,
                "customerName": name,
                "address": address,
                "detail": content,
                "phone": contact,
                "budget": deposit,
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("이미 존재하는 연락처 입니다.")
                break
        }
    }, [backendState])


    return [updateEvent]
}

export default useCustomerUpdateRequest