import { useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useContentListRequest = (page, content) => {

    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        if (content) {
            setBackendState("GET", `/admin/contents/search?page=${page - 1}&title=${content}`)
        } else {
            setBackendState("GET", `/admin/contents?page=${page - 1}`)
        }
    }, [page, content])

    useEffect(() => {
        switch (backendState?.code) {
            case 400:
                console.log("Invalid Request")
                break
        }
    }, [backendState])


    return [backendState]
}

export default useContentListRequest