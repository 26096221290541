import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"

const usePropertyDetailRequest = (idx) => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        setBackendState("GET", `/admin/properties/detail?propertyId=${idx}`)
    }, [])

    useEffect(() => {
        switch (backendState?.code) {
            case 400:
                console.log("Invalid Request")
                break
            case 404:
                navigate(`/404`)
                break
        }
    }, [backendState])


    return [backendState]
}

export default usePropertyDetailRequest