import { Link } from "react-router-dom"

import Style from "./style"
import useLogoutEvent from "./model/useLogoutEvent"
import { adminMenuList, managerMenuList, reporterMenuList } from "./asset/menuList"

import usePageAtom from "shared/model/usePageAtom"
import useRoleCookie from "shared/model/useRoleCookie"

const Aside = () => {

    const [roleCookie] = useRoleCookie()
    const [pageState] = usePageAtom()
    const [logoutEvent] = useLogoutEvent()

    const menuList = roleCookie === 2
        ? adminMenuList
        : roleCookie === 4
            ? managerMenuList
            : roleCookie === 3
                ? reporterMenuList
                : []

    return (
        <>
            {roleCookie &&
                <Style.Aside>
                    <Style.ListContainer>
                        {menuList?.map(category =>
                            <Style.CategoryDiv>
                                <Style.ItemTitleLabel>{category.title}</Style.ItemTitleLabel>
                                {category?.items.map(item =>
                                    <Link to={item?.path}>
                                        <Style.ItemElemButton nowPage={pageState === item?.path}>{item?.name}</Style.ItemElemButton>
                                    </Link>)}
                            </Style.CategoryDiv>)}
                    </Style.ListContainer>
                    <Style.LogoutBtn onClick={logoutEvent}>로그아웃</Style.LogoutBtn>
                </Style.Aside>}
        </>
    )
}

export default Aside