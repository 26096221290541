import React from "react";

import Style from "./style"

const ActiveBtn = ({ label, clickEvent }) => {

    return (
        <Style.Btn onClick={clickEvent}>{label}</Style.Btn>
    )
}

export default ActiveBtn