import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useContentCreateRequest = () => {
    const navigate = useNavigate()

    const [, setErrorModal] = useErrorModalAtom()
    const [backendState, setBackendState] = useFetch()

    const exception = (title, image, content) => {
        if (!title.match(regx.max100)) {
            setErrorModal("제목 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("내용 값을 확인해주세요.")
            return false
        }
        if (!image) {
            setErrorModal("이미지를 업로드해 주세요.")
            return false
        }

        return true
    }

    const createEvent = (title, image, content) => {
        if (exception(title, image, content)) {
            const formData = new FormData()
            formData.append("title", title)
            formData.append("paragraph", content)
            formData.append("imageList", image)
            setBackendState("POST", `/admin/contents`, null, formData)
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("Invalid Argument")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useContentCreateRequest