export const keyToIndex = {
    "ROLE_ADMIN": 2,
    "ROLE_REPORTER": 3,
    "ROLE_MANAGER": 4
}

export const keyToValue = {
    "ROLE_ADMIN": "관리자",
    "ROLE_MANAGER": "프로젝트 매니저",
    "ROLE_REPORTER": "기자"
}

export const indexToRouter = {
    "2": "/account",
    "3": "/content",
    "4": "/content",
}

export const indexToValue = {
    "2": "관리자",
    "3": "기자",
    "4": "프로젝트 매니저"
}

export const valueToKey = {
    "관리자": "ROLE_ADMIN",
    "프로젝트 매니저": "ROLE_MANAGER",
    "기자": "ROLE_REPORTER"
}

