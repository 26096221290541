import { useEffect } from "react"

import useUserAtom from "shared/model/useUserAtom"
import useFetch from "shared/model/useFetch"
import useAuthCookie from "shared/model/useAuthCookie"
import useRoleCookie from "shared/model/useRoleCookie"
import useErrorModalAtom from "shared/model/useErrorModalAtom"

const useAutoLoginRequest = () => {
    const [getAccountState, setGetAccountState] = useFetch()
    const [authCookie, , removeAuthCookie] = useAuthCookie()
    const [, , removeRoleCookie] = useRoleCookie()
    const [, setUserAtom, resetUserAtom] = useUserAtom()
    const [, setErrorModal] = useErrorModalAtom()

    // 쿠키 있을 때 이름 가져오기 Request
    useEffect(() => {
        if (authCookie) {
            setGetAccountState("POST", "/account/admin/auth", {
                "accessToken": `${authCookie}`
            })
        }
    }, [authCookie])

    // 자동 로그인 Request 후처리
    useEffect(() => {
        switch (getAccountState?.code) {
            case 200:
                setUserAtom(getAccountState?.data?.name)
                break
            case 400:
                setErrorModal(`사용 정지된 계정입니다.`)
                removeAuthCookie()
                removeRoleCookie()
                resetUserAtom()
                break
            case 404:
                setErrorModal(`세션이 만료되었습니다. \n다시 로그인해주세요.`)
                removeAuthCookie()
                removeRoleCookie()
                resetUserAtom()
                break
        }
    }, [getAccountState])
}

export default useAutoLoginRequest