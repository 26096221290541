const calFontStyle = (font, size, weight, lineHeight, letterSpacing) => {
    return `
        font-family: ${font};
        font-size: ${size};
        font-weight: ${weight};
        line-height: ${lineHeight};
        letter-spacing: ${letterSpacing};
    `
}

export default calFontStyle