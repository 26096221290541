import Style from "./style"
import usePagenationEvent from "./model/usePagenationEvent"
import calRange from "./lib/calProperPageRange"
import leftBlackSvg from "./asset/left_black.svg"
import leftGreySvg from "./asset/left_grey.svg"
import rightBlackSvg from "./asset/right_black.svg"
import rightGreySvg from "./asset/right_grey.svg"

const Pagenation = ({ nowPage, maxPage, searchKey }) => {

    const range = calRange(nowPage, maxPage)
    const [prePageEvent, movePageEvent, nextPageEvent] = usePagenationEvent(nowPage, maxPage, searchKey)

    return (
        <Style.WrapperDiv >
            <Style.Button active={nowPage > 1} onClick={prePageEvent}>
                {nowPage > 1 ? <img src={leftBlackSvg} /> : <img src={leftGreySvg} />} 이전
            </Style.Button>
            <Style.PageDiv>
                {range?.map((elem) => {
                    return (
                        <Style.PageButton active={parseInt(nowPage) === elem} onClick={() => movePageEvent(elem)}>{elem}</Style.PageButton>
                    )
                })}
            </Style.PageDiv>
            <Style.Button active={nowPage < maxPage} onClick={nextPageEvent}>
                다음 {nowPage < maxPage ? <img src={rightBlackSvg} /> : <img src={rightGreySvg} />}
            </Style.Button>
        </Style.WrapperDiv>
    )
}

export default Pagenation 