import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"

const usePropertyDeleteRequest = (idx) => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()

    const requestEvent = () => {
        setBackendState("DELETE", `/admin/properties?propertyId=${idx}`)
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                console.log("Invalid Request")
                break
        }
    }, [backendState])


    return [requestEvent]
}

export default usePropertyDeleteRequest