const convertTimestamp = (timestamp) => {

    const datetime = new Date(timestamp)
    datetime.setHours(datetime.getHours() + 9)

    const datetimeStr = datetime.toLocaleString()

    return datetimeStr
}

export default convertTimestamp