import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import Style from "./style"
import useContentCreateRequest from "./api/useContentCreateRequest"

import TitleArea from "widgets/titleArea"
import ContentWriteArea from "widgets/contentWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const ContentWrite = () => {
    const navigate = useNavigate()
    const titleRef = useRef()
    const contentRef = useRef()
    const [imgFile, setImgFile] = useState(null)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [contentCreateEvent] = useContentCreateRequest()

    useEffect(() => {
        setPageState("/content")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => navigate(-1)
        },
        {
            "label": "생성",
            "clickEvent": () => contentCreateEvent(
                titleRef?.current?.value,
                imgFile,
                contentRef?.current?.value
            )
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="콘텐츠 생성" buttonList={buttonList} />
                <ContentWriteArea
                    titleRef={titleRef}
                    setImgFile={setImgFile}
                    contentRef={contentRef}
                />
            </Style.Main>
        </>
    )
}

export default ContentWrite