import styled from "styled-components"

export default {
    "BgDiv": styled.div`
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        min-height: 100vh;
        background-color: ${({ theme }) => theme.color.opacity};
    `,
    "ModalDiv": styled.div`
        width: 360px;
    `,
    "Label": styled.p`
        width: 100%;
        padding: 80px 0px;
        ${({ theme }) => theme.font.medium_bold};
        text-align: center;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 24px 24px 0px 0px;
    `,
    "ButtonsDiv": styled.div`
        width: 100%;
        display: flex;
    `,
    "Btn": styled.button`
        flex-grow: 1;
        padding: 12px 0px;
        ${({ theme }) => theme.font.small_bold};
        background-color: ${({ theme, cancel }) => cancel ? theme.color.grey : theme.color.major};
        color: ${({ theme, cancel }) => cancel? theme.color.black : theme.color.white};
        border-radius: ${({cancel}) => cancel ? "0px 0px 24px 0px" : "0px 0px 0px 24px"};
    `,
}