import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        height: 40px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid ${({ theme }) => theme.color.grey};
        padding-bottom: 8px;
        margin-bottom: 24px;
    `,
    "TitleLabel": styled.h1`
        ${({ theme }) => theme.font.small_bold}
    `,
    "BtnDiv": styled.div`
        display: flex;
        width: fit-content;
        gap: 8px;
    `,
    "Btn": styled.button`
        height: 100%;
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.major};
        border: 1px solid ${({ theme }) => theme.color.major};
        padding: 0px 16px;
        border-radius: 4px;
    `,

}