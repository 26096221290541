import styled from "styled-components"

export default {
    "Footer": styled.footer`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background-color: ${({ theme }) => theme.color.black}
    `,
    "CopywriteLabel": styled.h2`
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.white};
    `
}