import { ThemeProvider } from "styled-components"

import "./globalFont/font.css"

import Style from "./style"
import theme from "./theme"
import GlobalStyle from "./globalStyle"
import useAutoLoginRequest from "./useAutoLoginRequest"
import TextModal from "./textModal"
import ConfirmModal from "./confirmModal"
import Spinner from "./spinner"

import Router from "pages"
import Header from "widgets/header"
import Aside from "widgets/aside"
import Footer from "widgets/footer"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"
import useSpinnerAtom from "shared/model/useSpinnerAtom"

const App = () => {
    const [isErrorModal] = useErrorModalAtom()
    const [isConfrimModal] = useConfirmModalAtom()
    const [isSpinner] = useSpinnerAtom()

    useAutoLoginRequest()

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Style.ParentDiv>
                {isErrorModal && <TextModal />}
                {isConfrimModal && <ConfirmModal />}
                {isSpinner && <Spinner />}
                <Header />
                <Style.WrapperDiv>
                    <Aside />
                    <Router />
                </Style.WrapperDiv>
                <Footer />
            </Style.ParentDiv>
        </ThemeProvider>
    )
}

export default App