import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useAdminUpdateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (idx, id, pw, pwConfirm, name) => {
        if (!idx.match(regx.number)) {
            setErrorModal("수정하려는 계정의 idx값을 확인해주세요.")
            return false
        }
        if (!id.match(regx.max20)) {
            setErrorModal("아이디 값을 확인해주세요.")
            return false
        }
        if (!pw.match(regx.password)) {
            setErrorModal("비밀번호 값을 확인해주세요.")
            return false
        }
        if (pw !== pwConfirm) {
            setErrorModal("비밀번호가 서로 일치하지 않습니다.")
            return false
        }
        if (!name.match(regx.max10)) {
            setErrorModal("이름 값을 확인해주세요.")
            return false
        }

        return true
    }

    const updateEvent = (idx, id, pw, pwConfirm, name, preAuth, newAuth) => {

        console.log(idx, id, pw, pwConfirm, name, preAuth, newAuth)

        if (exception(idx, id, pw, pwConfirm, name, preAuth, newAuth)) {
            setBackendState("POST", `/admin/accounts/edit`, {
                "userId": idx,
                "account": id,
                "password": pw,
                "name": name,
                "authorityName": preAuth,
                "newAuthorityName": newAuth
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("이미 사용중인 아이디 입니다.")
                break
        }
    }, [backendState])


    return [updateEvent]
}

export default useAdminUpdateRequest