import styled from "styled-components";

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
        gap: 24px;
    `,
    "Line": styled.hr`
        width: 100%;
        border: 0;
        height: 1px;
        background: ${({ theme }) => theme.color.grey};
    `
}