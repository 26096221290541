import { useEffect, useRef } from "react"

import Style from "./style"
import useBannerUpdateRequest from "./api/useBannerUpdateRequest"
import useBannerListRequest from "./api/useBannerListRequest"

import TitleArea from "widgets/titleArea"
import BannerUpdateArea from "widgets/bannerUpdateArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const BannerUpdate = () => {
    const value1Ref = useRef()
    const value2Ref = useRef()
    const value3Ref = useRef()
    const value4Ref = useRef()
    const value5Ref = useRef()

    const [getUpdateEvent] = useBannerUpdateRequest()
    const [getBannerListState] = useBannerListRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/banner/update")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "저장",
            "clickEvent": () => {
                getUpdateEvent(
                    value1Ref?.current?.value,
                    value2Ref?.current?.value,
                    value3Ref?.current?.value,
                    value4Ref?.current?.value,
                    value5Ref?.current?.value
                )
            }
        }
    ]

    return (
        <Style.Main>
            <TitleArea label="배너 수정" buttonList={buttonList} />
            <BannerUpdateArea
                initData={getBannerListState?.data || []}
                valueRefList={[value1Ref, value2Ref, value3Ref, value4Ref, value5Ref]}
            />
        </Style.Main>
    )
}

export default BannerUpdate