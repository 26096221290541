import { useEffect, useRef } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"

import Style from "./style"
import usePropertyListRequest from "./api/usePropertyListRequest"

import TitleArea from "widgets/titleArea"
import DataListArea from "widgets/dataListArea"
import InputBtn from "features/inputBtn"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const PropertyList = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const nowPage = parseInt(searchParams.get("page")) || 1
    const searchKeyword = searchParams.get("search") || ""
    const searchRef = useRef()

    const [getPropertyListState] = usePropertyListRequest(nowPage, searchKeyword)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/property")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "생성",
            "clickEvent": () => {
                navigate(`${location.pathname}/write`)
            }
        }
    ]

    const titleList = [
        {
            "key": "propertyId",
            "label": "IDX",
        },
        {
            "key": "propertyName",
            "label": "매물 명"
        },
        {
            "key": "address",
            "label": "주소",
            "size": "expand"
        },
        {
            "key": "author",
            "label": "작성자",
        },
        {
            "key": "createdAt",
            "label": "가입 날짜",
            "size": "date"
        }
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="검색" />
                <InputBtn placeholder={"매물 명 혹은 주소 입력"} inputRef={searchRef} maxLength={20} btnLabel={"검색"} btnEvent={() => navigate(`${location.pathname}?page=1&search=${searchRef?.current.value}`)} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="매물 관리" buttonList={buttonList} />
                <DataListArea nowPage={nowPage} searchKey={searchKeyword} titleList={titleList} dataList={getPropertyListState?.data} expandIndex={2} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default PropertyList