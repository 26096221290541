import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import Style from "./style"
import useAdminCreateRequest from "./api/useAdminCreateRequest"

import TitleArea from "widgets/titleArea"
import AdminWriteArea from "widgets/adminWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import { valueToKey } from "shared/const/accountRole"

const AdminWrite = () => {
    const navigate = useNavigate()
    const idRef = useRef()
    const pwRef = useRef()
    const pwConfirmRef = useRef()
    const nameRef = useRef()
    const authRef = useRef()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [adminCreateEvent] = useAdminCreateRequest()

    useEffect(() => {
        setPageState("/admin")
        setPageNameState("계정 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => {
                navigate(-1)
            }
        },
        {
            "label": "생성",
            "clickEvent": () => {
                adminCreateEvent(
                    idRef?.current?.value,
                    pwRef?.current?.value,
                    pwConfirmRef?.current?.value,
                    nameRef?.current?.value,
                    valueToKey[authRef?.current?.options[authRef?.current.selectedIndex].value]
                )
            }
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="관리자 계정 생성" buttonList={buttonList} />
                <AdminWriteArea
                    idRef={idRef}
                    pwRef={pwRef}
                    pwConfirmRef={pwConfirmRef}
                    nameRef={nameRef}
                    authRef={authRef}
                />
            </Style.Main>
        </>
    )
}

export default AdminWrite