import Style from "./style"

import useRoleCookie from "shared/model/useRoleCookie"

const Footer = () => {
    const [roleCookie] = useRoleCookie()

    return (
        <>
            {!roleCookie &&
                <Style.Footer>
                    <Style.CopywriteLabel>ⓒ 2024 Comming Brand Co., Ltd. All righs reserved.</Style.CopywriteLabel>
                </Style.Footer>}
        </>
    )
}

export default Footer