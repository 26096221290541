import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Style from "./style"
import useAdminDetailRequest from "./api/useAdminDetailRequest"
import useAdminDeactiveRequest from "./api/useAdminDeactiveRequest"
import useAdminActiveRequest from "./api/useAdminActiveRequest"
import useAdminDeleteRequest from "./api/useAdminDeleteRequest"

import TitleArea from "widgets/titleArea"
import AdminDetailArea from "widgets/adminDetailArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const AdminDetail = () => {
    const params = useParams()
    const userIdx = params.idx
    const navigate = useNavigate()
    const [, setDeactiveModalState] = useConfirmModalAtom()
    const [, setDeleteModalState] = useConfirmModalAtom()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()

    const [getAdminDetailState] = useAdminDetailRequest(userIdx)
    const [deactiveAdminEvent] = useAdminDeactiveRequest(userIdx)
    const [ativeAdminEvent] = useAdminActiveRequest(userIdx)
    const [deleteAdminEvent] = useAdminDeleteRequest(userIdx)

    useEffect(() => {
        setPageState("/admin")
        setPageNameState("관리자 계정 관리")
    }, [])

    const banBtn = {
        "label": "정지",
        "clickEvent": () => setDeactiveModalState("계정을 정지할까요?", () => deactiveAdminEvent)
    }

    const unbanBtn = {
        "label": "정지 해제",
        "clickEvent": () => setDeactiveModalState("정지를 해제할까요?", () => ativeAdminEvent)
    }

    const optionBtnList = [
        {
            "label": "수정",
            "clickEvent": () => { navigate(`/admin/update/${userIdx}`) }
        },
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("계정을 삭제할까요?", () => deleteAdminEvent)
        },
    ]

    const btnList = getAdminDetailState?.data?.state === "BANNED"
        ? [unbanBtn, ...optionBtnList]
        : [banBtn, ...optionBtnList]

    return (
        <>
            <Style.Main>
                <TitleArea label="관리자 계정 상세보기" buttonList={btnList} />
                <AdminDetailArea dataDetail={getAdminDetailState?.data} />
            </Style.Main>
        </>
    )
}

export default AdminDetail