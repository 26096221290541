import { useNavigate, useLocation } from "react-router-dom";

const useMoveDetailEvent = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const moveEvent = (idx) => {
        navigate(`${location.pathname}/detail/${idx}`)
    }

    return [moveEvent]
}

export default useMoveDetailEvent