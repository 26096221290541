import React from "react";

import Style from "./style"

import LabelInput from "features/labelInput";

const ContentWriteArea = ({ titleRef, setImgFile, contentRef, initData }) => {

    return (
        <Style.Wrapper>
            <LabelInput label={"제목"} value={initData?.title} placeholder={"최대 100글자 제한"} inputRef={titleRef} maxLength={100} />
            <LabelInput type={"file"} value={initData?.imageList[0]} label={"썸네일 첨부"} btnLabel={"첨부하기"} setFile={setImgFile} />
            <LabelInput type={"textarea"} value={initData?.paragraph} label={"내용"} inputRef={contentRef} />
        </Style.Wrapper>
    )
}

export default ContentWriteArea