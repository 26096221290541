import React from "react";

import Style from "./style"

import useErrorModalAtom from "shared/model/useErrorModalAtom";

const TextModal = () => {

    const [label, , resetState] = useErrorModalAtom()

    return (
        <Style.BgDiv onClick={resetState}>
            <Style.ModalDiv>
                <Style.Label>{label}</Style.Label>
                <Style.Btn onClick={resetState}>확인</Style.Btn>
            </Style.ModalDiv>
        </Style.BgDiv>
    )
}

export default TextModal