import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useBrandCreateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (name, image, birth, number, sales, franchisee, education, content, contact, duplicateCheck) => {
        if (!name?.match(regx.max50)) {
            setErrorModal("브랜드 명 값을 확인해주세요.")
            return false
        }
        if (!image) {
            setErrorModal("이미지를 업로드해 주세요.")
            return false
        }
        if (!birth?.match(regx.birth)) {
            setErrorModal("설립년월 값을 확인해주세요.")
            return false
        }
        if (!number?.match(regx.number)) {
            setErrorModal("가맹점 수 값을 확인해주세요.")
            return false
        }
        if (!sales?.match(regx.number)) {
            setErrorModal("전체 월 평균 매출 값을 확인해주세요.")
            return false
        }
        if (!franchisee?.match(regx.number)) {
            setErrorModal("가맹비 값을 확인해주세요.")
            return false
        }
        if (!education?.match(regx.number)) {
            setErrorModal("교육비 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("메뉴 값을 확인해주세요.")
            return false
        }
        if (!contact?.match(regx.contact)) {
            setErrorModal("연락처 값을 확인해주세요.")
            return false
        }
        if (!duplicateCheck) {
            setErrorModal("연락처 중복 확인을 진행해 주세요.")
            return false
        }

        return true
    }

    const createEvent = (name, image, birth, number, sales, franchisee, education, content, contact, duplicateCheck) => {
        if (exception(name, image, birth, number, sales, franchisee, education, content, contact, duplicateCheck)) {
            const formData = new FormData()
            formData.append("brandName", name)
            formData.append("multipartFile", image)
            formData.append("buildDate", birth)
            formData.append("franchiseeCount", number)
            formData.append("totalMonthlySalesAvg", sales)
            formData.append("franchiseeFee", franchisee)
            formData.append("educateFee", education)
            formData.append("menu", content)
            formData.append("phone", contact)
            setBackendState("POST", `/admin/brands`, null, formData)
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("이미 존재하는 연락처 입니다.")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useBrandCreateRequest