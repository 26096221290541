import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useCustomerDetailRequest from "./api/useCustomerDetailRequest"
import useCustomerUpdateRequest from "./api/useCustomerUpdateRequest"

import TitleArea from "widgets/titleArea"
import CustomerWriteArea from "widgets/customerWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const CustomerManagementWrite = () => {
    const navigate = useNavigate()
    const params = useParams()
    const userIdx = params.idx

    const nameRef = useRef()
    const addressRef = useRef()
    const contentRef = useRef()
    const depositRef = useRef()

    const [getCustomerDetailState] = useCustomerDetailRequest(userIdx)
    const [customerUpdateEvent] = useCustomerUpdateRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/customer-management")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => navigate(-1)
        },
        {
            "label": "저장",
            "clickEvent": () => customerUpdateEvent(
                userIdx,
                nameRef?.current?.value,
                addressRef?.current?.value,
                contentRef?.current?.value,
                getCustomerDetailState?.data?.phone,
                depositRef?.current?.value?.replace(/[^0-9]/g, "")
            )
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="개인 고객 생성" buttonList={buttonList} />
                <CustomerWriteArea
                    nameRef={nameRef}
                    addressRef={addressRef}
                    contentRef={contentRef}
                    depositRef={depositRef}
                    initData={getCustomerDetailState?.data}
                />
            </Style.Main>
        </>
    )
}

export default CustomerManagementWrite