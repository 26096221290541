import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useBrandDetailRequest from "./api/useBrandDetailRequest"
import useBrandUpdateRequest from "./api/useBrandUpdateRequest"

import TitleArea from "widgets/titleArea"
import BrandWriteArea from "widgets/brandWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const BrandManagementUpdate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const brandIdx = params.idx

    const nameRef = useRef()
    const [imgFile, setImgFile] = useState(null)
    const birthRef = useRef()
    const numberRef = useRef()
    const salesRef = useRef()
    const franchiseeRef = useRef()
    const educateRef = useRef()
    const contentRef = useRef()

    const [getBrandDetailState] = useBrandDetailRequest(brandIdx)
    const [brandUpdateEvent] = useBrandUpdateRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/brand-management")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => navigate(-1)
        },
        {
            "label": "저장",
            "clickEvent": () => brandUpdateEvent(
                brandIdx,
                nameRef?.current?.value,
                imgFile,
                birthRef?.current?.value,
                numberRef?.current?.value,
                salesRef?.current?.value?.replace(/[^0-9]/g, ""),
                franchiseeRef?.current?.value?.replace(/[^0-9]/g, ""),
                educateRef?.current?.value?.replace(/[^0-9]/g, ""),
                contentRef?.current?.value,
                getBrandDetailState?.data?.phone,
                getBrandDetailState?.data?.brandCardImage?.imageUrl
            )
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="브랜드 생성" buttonList={buttonList} />
                <BrandWriteArea
                    nameRef={nameRef}
                    setImgFile={setImgFile}
                    birthRef={birthRef}
                    numberRef={numberRef}
                    salesRef={salesRef}
                    franchiseeRef={franchiseeRef}
                    educateRef={educateRef}
                    contentRef={contentRef}
                    initData={getBrandDetailState?.data}
                />
            </Style.Main>
        </>
    )
}

export default BrandManagementUpdate