import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useErrorModalAtom from "shared/model/useErrorModalAtom"
import useFetch from "shared/model/useFetch"
import regx from "shared/const/regx"

const useBrandUpdateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (idx, name, birth, number, sales, franchisee, education, content) => {
        if (!idx.match(regx.number)) {
            setErrorModal("수정하려는 브랜드의 idx값을 확인해주세요.")
            return false
        }
        if (!name?.match(regx.max50)) {
            setErrorModal("브랜드 명 값을 확인해주세요.")
            return false
        }
        if (!birth?.match(regx.birth)) {
            setErrorModal("설립년월 값을 확인해주세요.")
            return false
        }
        if (!number?.match(regx.number)) {
            setErrorModal("가맹점 수 값을 확인해주세요.")
            return false
        }
        if (!sales?.match(regx.number)) {
            setErrorModal("전체 월 평균 매출 값을 확인해주세요.")
            return false
        }
        if (!franchisee?.match(regx.number)) {
            setErrorModal("가맹비 값을 확인해주세요.")
            return false
        }
        if (!education?.match(regx.number)) {
            setErrorModal("교육비 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("메뉴 값을 확인해주세요.")
            return false
        }

        return true
    }

    const updateEvent = (idx, name, image, birth, number, sales, franchisee, education, content, contact, oldImagePath) => {
        if (exception(idx, name, birth, number, sales, franchisee, education, content)) {
            const formData = new FormData()
            formData.append("brandId", idx)
            formData.append("brandName", name)
            if (image) {
                formData.append("newBusinessCardImage", image)
                formData.append("deletedCardImageUrl", oldImagePath)
            } else {
                formData.append("deletedCardImageUrl", "")
            }
            formData.append("buildDate", birth)
            formData.append("franchiseeCount", number)
            formData.append("totalMonthlySalesAvg", sales)
            formData.append("franchiseeFee", franchisee)
            formData.append("educateFee", education)
            formData.append("menu", content)
            formData.append("phone", contact)
            setBackendState("POST", `/admin/brands/edit`, null, formData)
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("입력 값이 잘못되었습니다.")
                break
        }
    }, [backendState])


    return [updateEvent]
}

export default useBrandUpdateRequest