import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import useContentUpdateRequest from "./api/useContentUpdateRequest"
import useContentDetailRequest from "./api/useContentDetailRequest"

import TitleArea from "widgets/titleArea"
import ContentWriteArea from "widgets/contentWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const ContentUpdate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const contentIdx = params.idx

    const titleRef = useRef()
    const contentRef = useRef()
    const [imgFile, setImgFile] = useState(null)

    const [getContentDetailState] = useContentDetailRequest(contentIdx)
    const [contentUpdateEvent] = useContentUpdateRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/content")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => navigate(-1)
        },
        {
            "label": "저장",
            "clickEvent": () => contentUpdateEvent(
                contentIdx,
                titleRef?.current?.value,
                imgFile,
                contentRef?.current?.value,
                getContentDetailState?.data.imageList[0]
            )
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="콘텐츠 생성" buttonList={buttonList} />
                <ContentWriteArea
                    titleRef={titleRef}
                    setImgFile={setImgFile}
                    contentRef={contentRef}
                    initData={getContentDetailState?.data}
                />
            </Style.Main>
        </>
    )
}

export default ContentUpdate