import React from "react";

import Style from "./style"
import icon from "./asset/icon.svg"

const LabelSelect = ({ label, optionList, value, inputRef }) => {

    return (
        <Style.WrapperDiv>
            <Style.Label>{label}</Style.Label>
            <Style.SelectDiv src={icon}>
                <Style.Select ref={inputRef}>
                    {optionList?.map((elem, index) =>
                        <option value={elem} selected={optionList.indexOf(value) === index}>{elem}</option>
                    )}
                </Style.Select>
            </Style.SelectDiv>
        </Style.WrapperDiv>
    )
}

export default LabelSelect