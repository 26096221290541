import React from "react";

import Style from "./style"

const LoginInput = ({ type = "text", label, placeholder, inputRef }) => {

    return (
        <Style.WrapperDiv>
            <Style.Label>{label}</Style.Label>
            <Style.Input type={type} placeholder={placeholder} ref={inputRef} />
        </Style.WrapperDiv>
    )
}

export default LoginInput