import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";

const AccountDetailArea = ({dataDetail}) => {

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.userId} />
            <TitleLabel title={"상태"} label={dataDetail?.state === "ACTIVE" ? "정상" : "정지된 계정"} caution={dataDetail?.state === "ACTIVE" ? false : true}/>
            <TitleLabel title={"아이디"} label={dataDetail?.account} />
            <TitleLabel title={"이름"} label={dataDetail?.name} />
            <TitleLabel title={"연락처"} label={dataDetail?.phone} />
            <TitleLabel title={"이메일"} label={dataDetail?.email} />
            <TitleLabel title={"가입 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
        </Style.Wrapper>
    )
}

export default AccountDetailArea