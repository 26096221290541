import React from "react";

import Style from "./style"

import TitleLabel from "features/titleLabel";
import MultipleTitleLabel from "features/multipleTitleLabel";

import convertTimestamp from "shared/lib/convertTimestamp";
import convertMoney from "shared/lib/convertMoney";

const PropertyDetailArea = ({ dataDetail }) => {

    const firstData = [
        {
            "title": "매물명",
            "label": dataDetail?.propertyName
        },
        {
            "title": "주소",
            "label": dataDetail?.address
        },
        {
            "title": "지역",
            "label": dataDetail?.region
        }
    ]
    const secondData = [
        {
            "title": "업종",
            "label": dataDetail?.sector
        },
        {
            "title": "면적 & 층",
            "label": `${dataDetail?.area} m^2 (${Math.round(dataDetail?.area / 3.3 * 100) / 100}평) ${dataDetail?.floor < 0 ? "지하" : ""} ${Math.abs(dataDetail?.floor)}층`
        },
        {
            "title": "임대료",
            "label": `${convertMoney(dataDetail?.rent)} 원`
        }
    ]
    const thirdData = [
        {
            "title": "보증금",
            "label": `${convertMoney(dataDetail?.deposit)} 원`
        },
        {
            "title": "시설금",
            "label": `${convertMoney(dataDetail?.keyMoney)} 원`
        },
        {
            "title": "로열티",
            "label": dataDetail?.royalty
        }
    ]
    const fourthData = [
        {
            "title": "임대 명의",
            "label": dataDetail?.leaseTitle
        }
    ]

    return (
        <Style.Wrapper>
            <TitleLabel title={"IDX"} label={dataDetail?.propertyId} />
            <TitleLabel title={"작성자"} label={dataDetail?.author} />
            <TitleLabel title={"상태"} label={dataDetail?.propertyStatus} />
            <TitleLabel title={"생성 날짜"} label={convertTimestamp(dataDetail?.createdAt)} />
            <Style.Line />
            <MultipleTitleLabel dataList={firstData} />
            <MultipleTitleLabel dataList={secondData} />
            <MultipleTitleLabel dataList={thirdData} />
            <MultipleTitleLabel dataList={fourthData} />
        </Style.Wrapper>
    )
}

export default PropertyDetailArea