import { useEffect, useRef } from "react"
import { useSearchParams, useNavigate, useLocation } from "react-router-dom"

import Style from "./style"
import useAccountListRequest from "./api/useAccountListRequest"

import TitleArea from "widgets/titleArea"
import DataListArea from "widgets/dataListArea"
import InputBtn from "features/inputBtn"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const AccountList = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()
    const nowPage = parseInt(searchParams.get("page")) || 1
    const searchKeyword = searchParams.get("search") || ""
    const searchRef = useRef()

    const [getAccountListState] = useAccountListRequest(nowPage, searchKeyword)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/account")
        setPageNameState("계정 관리")
    }, [])

    const titleList = [
        {
            "key": "userId",
            "label": "IDX",
        },
        {
            "key": "name",
            "label": "이름"
        },
        {
            "key": "account",
            "label": "아이디",
            "size": "expand"
        },
        {
            "key": "createdAt",
            "label": "가입 날짜",
            "size": "date"
        }
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="검색" />
                <InputBtn placeholder={"이름 혹은 아이디 입력"} inputRef={searchRef} maxLength={20} btnLabel={"검색"} btnEvent={() => navigate(`${location.pathname}?page=1&search=${searchRef?.current.value}`)} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="고객 계정 관리" />
                <DataListArea nowPage={nowPage} searchKey={searchKeyword} titleList={titleList} dataList={getAccountListState?.data} expandIndex={2} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default AccountList