import { useNavigate } from "react-router-dom"

import useAuthCookie from "shared/model/useAuthCookie"
import useRoleCookie from "shared/model/useRoleCookie"
import useUserAtom from "shared/model/useUserAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const useLogoutEvent = () => {
    const navigate = useNavigate()

    const [, , deleteTokenCookie] = useAuthCookie()
    const [, , deleteRoleCookie] = useRoleCookie()
    const [, , resetUserData] = useUserAtom()
    const [, setModalEvent] = useConfirmModalAtom()

    const logout = () => {
        deleteTokenCookie()
        deleteRoleCookie()
        resetUserData()
        navigate("/")
    }

    const logoutModelEvent = () => {
        setModalEvent("로그아웃 할까요?", () => logout)
    }

    return [logoutModelEvent]
}

export default useLogoutEvent