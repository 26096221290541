import { useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import usePropertyDetailRequest from "./api/usePropertyDetailRequest"
import usePropertyUpdateRequest from "./api/usePropertyUpdateRequest"

import TitleArea from "widgets/titleArea"
import PropertyWriteArea from "widgets/propertyWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const PropertyUpdate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const userIdx = params.idx

    const stateRef = useRef()
    const nameRef = useRef()
    const addressRef = useRef()
    const regionRef = useRef()
    const sectorRef = useRef()
    const depositRef = useRef()
    const keyMoneyRef = useRef()
    const areaRef = useRef()
    const floorRef = useRef()
    const rentRef = useRef()
    const royaltyRef = useRef()
    const ownerRef = useRef()

    const [getPropertyDetailState] = usePropertyDetailRequest(userIdx)
    const [propertyUpdateEvent] = usePropertyUpdateRequest()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/property")
        setPageNameState("데이터 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => {
                navigate(-1)
            }
        },
        {
            "label": "저장",
            "clickEvent": () => {
                propertyUpdateEvent(
                    userIdx,
                    stateRef?.current?.value,
                    nameRef?.current?.value,
                    addressRef?.current?.value,
                    regionRef?.current?.value,
                    sectorRef?.current?.value,
                    depositRef?.current?.value?.replace(/[^0-9]/g, ""),
                    keyMoneyRef?.current?.value?.replace(/[^0-9]/g, ""),
                    areaRef?.current?.value,
                    floorRef?.current?.value,
                    rentRef?.current?.value?.replace(/[^0-9]/g, ""),
                    royaltyRef?.current?.value,
                    ownerRef?.current?.value
                )
            }
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="매물 생성" buttonList={buttonList} />
                <PropertyWriteArea
                    stateRef={stateRef}
                    nameRef={nameRef}
                    addressRef={addressRef}
                    regionRef={regionRef}
                    sectorRef={sectorRef}
                    depositRef={depositRef}
                    keyMoneyRef={keyMoneyRef}
                    areaRef={areaRef}
                    floorRef={floorRef}
                    rentRef={rentRef}
                    royaltyRef={royaltyRef}
                    ownerRef={ownerRef}
                    initData={getPropertyDetailState?.data}
                />
            </Style.Main>
        </>
    )
}

export default PropertyUpdate