import { useEffect } from "react"

import Style from "./style"

import LoginArea from "widgets/loginArea"
import usePageAtom from "shared/model/usePageAtom"

const Login = () => {

    const [, setPage] = usePageAtom()
    useEffect(() => setPage("/login"), [])

    return (
        <Style.WrapperDiv>
            <Style.ExpandDiv>
                <LoginArea />
            </Style.ExpandDiv>
        </Style.WrapperDiv>
    )
}

export default Login