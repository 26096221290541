import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import regx from "shared/const/regx"

const useContactDuplicateCheck = () => {
    const [duplicateCheck, setDuplicateCheck] = useState(false)
    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (contact) => {
        if (!contact?.match(regx.contact)) {
            setErrorModal("연락처 값을 확인해주세요.")
            return false
        }

        return true
    }

    const checkEvent = (contact) => {
        if (exception(contact)) {
            setBackendState("POST", "/admin/brands/phone", {
                "phone": contact
            })
        }
    }

    const changeEvent = () => {
        setDuplicateCheck(false)
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setDuplicateCheck(true)
                break
            case 400:
                setErrorModal("이미 존재하는 브랜드 연락처 입니다.")
                break
        }
    }, [backendState])

    return [duplicateCheck, checkEvent, changeEvent]
}

export default useContactDuplicateCheck