import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import Style from "./style"
import useCustomerCreateRequest from "./api/useCustomerCreateRequest"
import useContactDuplicateCheck from "./api/useContactDupliocateCheck"

import TitleArea from "widgets/titleArea"
import CustomerWriteArea from "widgets/customerWriteArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"

const CustomerManagementWrite = () => {
    const navigate = useNavigate()
    const nameRef = useRef()
    const addressRef = useRef()
    const contentRef = useRef()
    const contactRef = useRef()
    const depositRef = useRef()

    const [customerCreateEvent] = useCustomerCreateRequest()
    const [duplicateCheck, duplicateCheckEvent, resetEvent] = useContactDuplicateCheck()

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/customer-management")
        setPageNameState("고객 관리")
    }, [])

    const buttonList = [
        {
            "label": "취소",
            "clickEvent": () => navigate(-1)
        },
        {
            "label": "생성",
            "clickEvent": () => customerCreateEvent(
                nameRef?.current?.value,
                addressRef?.current?.value,
                contentRef?.current?.value,
                contactRef?.current?.value,
                depositRef?.current?.value?.replace(/[^0-9]/g, ""),
                duplicateCheck
            )
        }
    ]

    return (
        <>
            <Style.Main>
                <TitleArea label="개인 고객 생성" buttonList={buttonList} />
                <CustomerWriteArea
                    nameRef={nameRef}
                    addressRef={addressRef}
                    contentRef={contentRef}
                    contactRef={contactRef}
                    depositRef={depositRef}
                    duplicateOkay={duplicateCheck}
                    duplicateCheckEvent={() => duplicateCheckEvent(contactRef?.current?.value)}
                    resetEvent={resetEvent}
                />
            </Style.Main>
        </>
    )
}

export default CustomerManagementWrite