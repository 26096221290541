import { useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Style from "./style"
import usePropertyDetailRequest from "./api/usePropertyDetailRequest"
import usePropertyDeleteRequest from "./api/usePropertyDeleteRequest"
import useMemoPostRequest from "./api/useMemoPostRequest"

import TitleArea from "widgets/titleArea"
import PropertyDetailArea from "widgets/propertyDetailArea"
import MemoArea from "widgets/memoArea"
import usePageAtom from "shared/model/usePageAtom"
import usePageNameAtom from "shared/model/usePageNameAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const PropertyDetail = () => {
    const params = useParams()
    const propertyIdx = params.idx
    const navigate = useNavigate()
    const memoRef = useRef()

    const [, setDeleteModalState] = useConfirmModalAtom()
    const [getPropertyDetailState] = usePropertyDetailRequest(propertyIdx)
    const [deletePropertyEvent] = usePropertyDeleteRequest(propertyIdx)
    const [postMemoEvent] = useMemoPostRequest(propertyIdx)

    const [, setPageState] = usePageAtom()
    const [, setPageNameState] = usePageNameAtom()
    useEffect(() => {
        setPageState("/property")
        setPageNameState("데이터 관리")
    }, [])

    const btnList = [
        {
            "label": "수정",
            "clickEvent": () => { navigate(`/property/update/${propertyIdx}`) }
        },
        {
            "label": "삭제",
            "clickEvent": () => setDeleteModalState("매물을 삭제할까요?", () => deletePropertyEvent)
        },
    ]

    return (
        <Style.WrapperDiv>
            <Style.AreaDiv>
                <TitleArea label="매물 상세보기" buttonList={btnList} />
                <PropertyDetailArea dataDetail={getPropertyDetailState?.data} />
            </Style.AreaDiv>
            <Style.AreaDiv>
                <TitleArea label="이 항목에 대한 메모" />
                <MemoArea data={getPropertyDetailState?.data?.memoList} inputRef={memoRef} postEvent={() => postMemoEvent(memoRef?.current?.value)} />
            </Style.AreaDiv>
        </Style.WrapperDiv>
    )
}

export default PropertyDetail