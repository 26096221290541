import { Routes, Route } from "react-router-dom"

import Style from "./style"
import { PublicRoute, PrivateRoute, ProtectRoute } from "./RouterCondition"

import Login from "./login"
import NotFound from "./notFound"
import InternalServerError from "./internalServerError"

import AccountList from "./accountList"
import AccountDetail from "./accountDetail"

import AdminList from "./adminList"
import AdminDetail from "./adminDetail"
import AdminWrite from "./adminWrite"
import AdminUpdate from "./adminUpdate"

import ContentList from "./contentList"
import ContentDetail from "./contentDetail"
import ContentWrite from "./contentWrite"
import ContentUpdate from "./contentUpdate"

import PropertyList from "./propertyList"
import PropertyDetail from "./propertyDetail"
import PropertytWrite from "./propertyWrite"
import PropertytUpdate from "./propertyUpdate"

import BannerUpdate from "./bannerUpdate"

import CustomerManagementList from "./customerManagementList"
import CustomerManagementDetail from "./customerManagementDetail"
import CustomerManagementWrite from "./customerManagementWrite"
import CustomerManagementUpdate from "./customerManagementUpdate"

import BrandManagementList from "./brandManagementList"
import BrandManagementDetail from "./brandManagementDetail"
import BrandManagementWrite from "./brandManagementWrite"
import BrandManagementUpdate from "./brandManagementUpdate"

import ConsultingList from "./consultingList"
import ConsultingDetail from "./consultingDetail"

const Router = () => {

    return (
        <Style.WrapperDiv>
            <Routes>

                {/* 비로그인 상태 필수 */}
                <Route element={<PublicRoute />}>
                    <Route index element={<Login />} />
                </Route>

                {/* 로그인 상태 필수 */}
                <Route element={<PrivateRoute />}>

                    {/* Role List */}
                    {/* 2: Admin / 3: Repoporter / 4: Manager */}
                    <Route path="account" element={<ProtectRoute permissionRoleList={[2]} />}>
                        <Route index element={<AccountList />} />
                        <Route path="detail/:idx" element={<AccountDetail />} />
                    </Route>
                    <Route path="admin" element={<ProtectRoute permissionRoleList={[2]} />}>
                        <Route index element={<AdminList />} />
                        <Route path="detail/:idx" element={<AdminDetail />} />
                        <Route path="write" element={<AdminWrite />} />
                        <Route path="update/:idx" element={<AdminUpdate />} />
                    </Route>
                    <Route path="content" element={<ProtectRoute permissionRoleList={[2, 3, 4]} />}>
                        <Route index element={<ContentList />} />
                        <Route path="detail/:idx" element={<ContentDetail />} />
                        <Route path="write" element={<ContentWrite />} />
                        <Route path="update/:idx" element={<ContentUpdate />} />
                    </Route>
                    <Route path="property" element={<ProtectRoute permissionRoleList={[2, 4]} />}>
                        <Route index element={<PropertyList />} />
                        <Route path="detail/:idx" element={<PropertyDetail />} />
                        <Route path="write" element={<PropertytWrite />} />
                        <Route path="update/:idx" element={<PropertytUpdate />} />
                    </Route>
                    <Route path="banner" element={<ProtectRoute permissionRoleList={[2, 4]} />}>
                        <Route path="update" element={<BannerUpdate />} />
                    </Route>
                    <Route path="customer-management" element={<ProtectRoute permissionRoleList={[2, 4]} />}>
                        <Route index element={<CustomerManagementList />} />
                        <Route path="detail/:idx" element={<CustomerManagementDetail />} />
                        <Route path="write" element={<CustomerManagementWrite />} />
                        <Route path="update/:idx" element={<CustomerManagementUpdate />} />
                    </Route>
                    <Route path="brand-management" element={<ProtectRoute permissionRoleList={[2, 4]} />}>
                        <Route index element={<BrandManagementList />} />
                        <Route path="detail/:idx" element={<BrandManagementDetail />} />
                        <Route path="write" element={<BrandManagementWrite />} />
                        <Route path="update/:idx" element={<BrandManagementUpdate />} />
                    </Route>
                    <Route path="consulting" element={<ProtectRoute permissionRoleList={[2, 4]} />}>
                        <Route index element={<ConsultingList />} />
                        <Route path="detail/:idx" element={<ConsultingDetail />} />
                    </Route>
                </Route>

                {/* 상관 없음 */}
                <Route path="/500" element={<InternalServerError />} />
                <Route path="*" element={<NotFound />} />

            </Routes>
        </Style.WrapperDiv >
    )
}

export default Router