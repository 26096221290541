import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useErrorModalAtom from "shared/model/useErrorModalAtom"
import regx from "shared/const/regx"

const useCustomerCreateRequest = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [, setErrorModal] = useErrorModalAtom()

    const exception = (name, address, content, contact, deposit, duplicateCheck) => {
        if (!name?.match(regx.max10)) {
            setErrorModal("고객 명 값을 확인해주세요.")
            return false
        }
        if (!address?.match(regx.max50)) {
            setErrorModal("주소 값을 확인해주세요.")
            return false
        }
        if (content === "") {
            setErrorModal("상세설명 값을 확인해주세요.")
            return false
        }
        if (!contact?.match(regx.contact)) {
            setErrorModal("연락처 값을 확인해주세요.")
            return false
        }
        if (!duplicateCheck) {
            setErrorModal("연락처 중복 확인을 진행해 주세요.")
            return false
        }
        if (!deposit?.match(regx.number)) {
            setErrorModal("예산 값을 확인해주세요.")
            return false
        }

        return true
    }

    const createEvent = (name, address, content, contact, deposit, duplicateCheck) => {
        if (exception(name, address, content, contact, deposit, duplicateCheck)) {
            setBackendState("POST", `/admin/customers`, {
                "customerName": name,
                "address": address,
                "detail": content,
                "phone": contact,
                "budget": deposit,
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate(-1)
                break
            case 400:
                setErrorModal("이미 존재하는 연락처 입니다.")
                break
        }
    }, [backendState])


    return [createEvent]
}

export default useCustomerCreateRequest