import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        display: flex;
        flex-direction: column;
        & + & {
            margin-top: 40px;
        }
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.medium_bold};
    `,
    "Input": styled.input`
        ${({ theme }) => theme.font.small};
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.color.placeholder};
        padding-bottom: 8px;
        margin-top: 16px;
    `
}