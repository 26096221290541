import { Navigate, Outlet } from "react-router-dom"

import useAuthCookie from "shared/model/useAuthCookie"
import useRoleCookie from "shared/model/useRoleCookie"
import { indexToRouter } from "shared/const/accountRole"

export const PublicRoute = () => {
    const [roleCookie] = useRoleCookie()

    return !roleCookie
        ? <Outlet />
        : <Navigate to={indexToRouter[roleCookie]} />
}

export const PrivateRoute = () => {
    const [authCookie] = useAuthCookie()

    return authCookie
        ? <Outlet />
        : <Navigate to="/" />
}

export const ProtectRoute = ({ permissionRoleList }) => {
    const [roleCookie] = useRoleCookie()

    return permissionRoleList.includes(roleCookie)
        ? <Outlet />
        : <Navigate to={indexToRouter[roleCookie]} />
}