import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `,
    "AreaDiv": styled.div`
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 16px;
        padding: 40px 64px;
    `
}